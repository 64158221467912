import { requestAuth } from './RequestService'

export async function readDim(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial) {
    // if (element.initial && element.validate) {
    const resultConvertDIM = await requestAuth(
      'put',
      `/files/convertPdfToCsv/${procedure.id}?FieldId=${element.id}`,
      {
        name: element.name,
      },
    )
      .then(async () => {
        const resultDataDIM = await requestAuth(
          'post',
          `/files/DataDim/${procedure.id}?FieldId=${element.id}`,
          {
            name: element.name,
          },
        )
          .then(async () => {
            const resultReadH = await requestAuth(
              'post',
              `/procedure/readH/${procedure.id}?FieldId=${element.id}`,
            )
              .then((resH) => {
                return {
                  readStatus: resH.status,
                  readData: resH.data,
                  validate: element.validate,
                }
              })
              .catch((err) => {
                return {
                  readStatus: err.response.status,
                  readData: err.response.data
                    ? err.response.data
                    : `Error al leer los documentos "${element.label}"`,
                  validate: element.validate,
                }
              })
            return resultReadH
          })
          .catch((err) => {
            return {
              readStatus: err.response.status,
              readData: err.response.data
                ? err.response.data
                : `Error al leer "${element.label}"`,
              validate: element.validate,
            }
          })
        return resultDataDIM
      })
      .catch((err) => {
        return {
          readStatus: err.response.status,
          readData: err.response.data
            ? err.response.data
            : `Error al leer "${element.label}"`,
          validate: element.validate,
        }
      })
    resultFn = resultConvertDIM
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(index + 1, fields, [...result, resultFn])
}

export async function adjuntarDex(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial) {
    // if (element.initial && element.validate) {
    const resultConvertDIM = await requestAuth(
      'put',
      `/files/convertPdfToCsv/${procedure.id}?FieldId=${element.id}`,
      {
        name: element.name,
      },
    )
      .then(async (res) => {
        return {
          readStatus: res.status,
          readData: res.data,
          validate: element.validate,
        }
      })
      .catch((err) => {
        return {
          readStatus: err.response.status,
          readData: err.response.data
            ? err.response.data
            : `Error al leer "${element.label}"`,
          validate: element.validate,
        }
      })
    resultFn = resultConvertDIM
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(index + 1, fields, [...result, resultFn])
}

export async function readDam(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial) {
    resultFn = await requestAuth(
      'put',
      `/files/ReadDam/${procedure.id}?FieldId=${element.id}`,
    )
      .then((res) => {
        return {
          readStatus: res.status,
          readData: res.data,
          validate: element.validate,
        }
      })
      .catch((err) => {
        return {
          readStatus: err.response.status,
          readData: err.response.data
            ? err.response.data
            : `Error al leer "${element.label}"`,
          validate: element.validate,
        }
      })
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(index + 1, fields, [...result, resultFn])
}

export async function readParte(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial) {
    const result = await requestAuth(
      'put',
      `/files/readparte/${procedure.id}?FieldId=${element.id}`,
    )
      .then(async (resp) => {
        return {
          readStatus: resp.status,
          readData: resp.data,
          validate: element.validate,
        }
        // const resultNotify = await requestAuth(
        //   'post',
        //   `/notification/parte/procedures/${procedure.id}`,
        // )
        //   .then((result) => {
        //     return {
        //       readStatus: result.status,
        //       readData: result.data,
        //       validate: element.validate,
        //     }
        //   })
        //   .catch((err) => {
        //     return {
        //       readStatus: err.response.status,
        //       readData: err.response.data
        //         ? err.response.data
        //         : `Error al crear la notificación del "${element.name}"`,
        //       validate: element.validate,
        //     }
        //   })
        // return resultNotify
      })
      .catch((err) => {
        return {
          readStatus: err.response.status,
          readData: err.response.data
            ? err.response.data
            : `Error al leer "${element.label}"`,
          validate: element.validate,
        }
      })
    resultFn = result
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(index + 1, fields, [...result, resultFn])
}
