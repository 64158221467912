import CallServiceTable from "../../components/Tables/CallServiceTable";
import { H3, Anchor } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import TableComplete from "../../components/Tables/TableComplete";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import ListDocument from "../../components/common/ListDocument";

const ClientList = () => {
  const { user } = useAuth();
  const { openDialog, dialogClose } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function viewProcedure(procedure) {
    openModal(
      <ProcedureData procedure={procedure} onClose={onClose} user={user} />
    );
  }
  function generateReport() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de tus tramites?</H3>
        }
        onClose={dialogClose}
        method="get"
        url={`/gamarra/${user?.company[0]?.id}/clientData `}
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }
  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <a
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </a>
        </center>
        <br />
      </div>
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} deleteDocument={false} />);
  }

  return (
    <div>
      <CallServiceTable
        onClick={viewProcedure}
        component={({ reload }) => {
          return (
            <Button
              className="text-lg py-3"
              onClick={() => generateReport(reload)}
            >
              <i className="fas fa-book"></i>
            </Button>
          );
        }}
        urlApi={`procedure/byuserid/${user?.user?.id}/companyId/${user?.company[0]?.id}/pag`}
        addFilters={[
          {
            name: "number",
            label: "Nro. proceso",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Número de DIM",
            filter: true,
          },
          // {
          //   name: "clientCode",
          //   label: "Código de Cliente",
          //   filter: true,
          // },
          // {
          //   name: "billNumber",
          //   label: "Número de factura",
          //   filter: true,
          // },
          // {
          //   name: "docEmbarque",
          //   label: "Nro de embarque",
          //   filter: true,
          // },
        ]}
        filters={[
          {
            name: "ForQueryDate",
            label: "Validar fecha por ...",
            options: [
              {
                label: "Fecha de inicio",
                name: "procedureStartDate",
              },
              {
                label: "Fecha de validación",
                name: "validateDate",
              },
              {
                label: "Fecha de facturación",
                name: "fechaFacturacion",
              },
            ],
          },
          {
            // url: '/company/getallcompanies',
            // name: 'companyId',
            // valueOption: 'id',
            // labelOption: 'razonSocial',
            type: "rangeDate",
          },
        ]}
        header={[
          // {
          //   name: 'companyName',
          //   label: 'Compañía',
          //   filter: false,
          // },
          {
            name: "number",
            label: "Nro Proceso",
          },
          {
            name: "clientCode",
            label: "Código de cliente",
          },
          {
            name: "dimNumber",
            label: "Nro DIM",
          },
          {
            name: "billProvider",
            label: "Proveedor",
          },
          {
            name: "clientBillNumber",
            label: "Número de factura",
          },
          {
            name: "validateDate",
            label: "Fecha de validación",
          },
          {
            name: "clientBillDate",
            label: "Fecha de facturación",
          },
          {
            name: "Reports",
            type: "action",
            label: "Reportes",
            actions: [
              {
                label: "Ver documentos",
                icon: "fa-solid fa-folder",
                action: handleDocument,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Generar informe de la recepción de documentos",
                icon: "fas fa-tasks",
                action: listDocumentPdf,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Generar liquidación - proforma",
                icon: "fas fa-coins",
                action: generateSettlementProforma,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        addUrl={`userId=${localStorage.getItem("id")}`}
        statusPaination={false}
        showTable={true}
      />
    </div>
  );
};

const ProcedureData = ({ procedure }) => {
  const { data, loading } = Request({
    urlApi: `/clientStats/procedures/${procedure.id}/data`,
  });
  if (!loading) {
    return <Circle />;
  }
  function hadleDocument(list) {
    let listDocument = [];
    list.forEach((dataSet) => {
      if ((dataSet.type = "fileStatus")) {
        listDocument = [...listDocument, ...dataSet.fileStates];
      } else {
        listDocument = [
          ...listDocument,
          { ...dataSet, url: dataSet.value, name: dataSet.label },
        ];
      }
    });
    return listDocument;
  }
  return (
    <div>
      <div className="bg-white mb-2 rounded-md p-2">
        <h3 className="text-xl font-semibold text-center">Datos</h3>
        {data.map((item) =>
          item.type !== "fileStatus" && item.type !== "file" ? (
            <div key={item.id} className="flex gap-2 mx-1 my-1">
              <H3>{item.label}:</H3>
              <H3 className="font-normal">{item.value}</H3>
            </div>
          ) : null
        )}
      </div>
      <div className="bg-white rounded-md">
        <h3 className="text-xl font-semibold text-center mb-2">Documentos</h3>
        <TableComplete
          header={[
            {
              name: "name",
              label: "Documento",
            },
            {
              name: "url",
              label: "Enlace",
              type: "custom",
              builder: (item) => {
                if (item) {
                  return (
                    <Anchor
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </Anchor>
                  );
                } else {
                  return "-";
                }
              },
            },
            {
              name: "number",
              label: "Nro",
            },

            {
              label: "Fecha",
              name: "creationDate",
              type: "date",
            },
            {
              name: "presentation",
              label: "Presentación",
            },
            {
              name: "status",
              label: "Estado",
            },
            {
              label: "Proveedor",
              name: "provider",
            },
          ]}
          data={hadleDocument(data)}
        />
      </div>
    </div>
  );
};

export default ClientList;
