import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Button from '../../components/Buttons/Button'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'
import { H3 } from '../../components/Text'
import { Tooltip } from 'react-tippy'
import ModalFullScreen from '../../components/Modal/ModalFullScreen'
import Texto from '../../components/Text/Texto'

const EditInstitution = ({ data, setreload, reload, active, call }) => {
  const [stateModal, setStateModal] = useState(false)
  return (
    <>
      <FormDefault
        tittle="Editar nombre de institución"
        fields={[
          {
            label: 'Nombre de institucion',
            name: 'Name',
            placeholder: 'Nombre de la institución',
            type: 'text',
            typeInput: 'input',
          },
        ]}
        initialValues={{
          Id: data?.id || '',
          Name: data?.name || '',
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={async (values, resetForm) => {
          await requestAuth('Put', 'institution', values)
            .then(() => {
              call()
              active(false)
              setreload(!reload)
              toast.success('Envio exitoso')
              resetForm()
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : 'Se produjo un error al enviar',
              )
            })
        }}
        buttonName="Enviar"
      />
      <div className="bg-white flex justify-evenly items-center">
        <H3>
          Estado de la institución {data.active == true ? 'activo' : 'inactivo'}{' '}
        </H3>
        <Tooltip
          className="m-0 sm:m-[10px] sm:mr-[20px]"
          title="Cambiar estado de la institucion"
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[30px] flex items-center justify-center m-0"
            onClick={() => {
              setStateModal(true)
            }}
          >
            <FontAwesomeIcon
              className="text-white text-[18px] sm:text-[20px]"
              icon="fa-solid fa-eye-slash"
            />
          </Button>
        </Tooltip>
      </div>
      <ModalFullScreen
        setactivateForm={setStateModal}
        activateForm={stateModal}
      >
        <Texto className="font-bold my-[10px] ">
          ¿ Cambiar el estado de la institución a{' '}
          {data?.active == true ? 'inactivo' : 'activo'} ?
        </Texto>
        <Button
          className="bg-red-500 text-white flex items-center justify-center my-[20px]"
          onClick={() => {
            requestAuth('put', `institution/${data.id}`)
              .then(() => {
                call()
                setreload(!reload)
                active(false)
                toast.success('Cambio de estado exitoso')
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : 'Se produjo un error al enviar',
                )
              })
          }}
        >
          Aceptar
        </Button>
      </ModalFullScreen>
    </>
  )
}

export default EditInstitution
