import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { Tooltip } from 'react-tippy'
import { useState } from 'react'
import { Button } from '../Buttons'
import { H3 } from '../Text'
import { Request } from '../../hooks/Request'
import { useDialog } from '../../contexts/DialogContext'
import { validateArray } from '../../utils/validate'
import { useLocation } from 'react-router-dom'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const GetTable = (props) => {
  const {
    urlApi,
    header,
    component: Component = () => null,
    onClick,
    reload,
    data: dataList = [],
    call: callList = () => {},
    handleRegister,
    restHeight,
  } = props

  const { data = [], loading, call } = Request({
    urlApi: urlApi ? urlApi : null,
  })

  useEffect(() => {
    if (loading) {
      call()
    }
  }, [reload])

  return (
    <>
      <Component
        {...props}
        reload={validateArray(dataList) ? callList : call}
      />
      <div
        style={{
          maxHeight: `calc(100vh - ${restHeight ? restHeight : 200}px)`,
        }}
        // className="mx-auto overflow-scroll"
        className="mx-auto overflow-scroll whitespace-nowrap"
      >
        <table className="w-full text-xs text-left text-gray-500">
          <thead className="text-[#1d4ed8]">
            <tr>
              {validateArray(header)
                ? header.map((head, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`text-xs border border-gray-300 ${
                        index === 0 ? 'sticky left-0' : ''
                      } p-1 bg-[#f1f5f9] ${
                        head.type === 'action' ? 'sticky right-0 px-0' : ''
                      }`}
                    >
                      {head.type === 'actionCell' ? (
                        <div>
                          <span className="mr-1">{head.label}</span>
                          <Button
                            onClick={() => head.action(head)}
                            className="px-1 py-0 right-0 top-0 text-xs"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Button>
                        </div>
                      ) : (
                        head.label
                      )}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <TableBody
            data={validateArray(dataList) ? dataList : data}
            header={header}
            onClick={onClick}
            handleRegister={handleRegister}
            call={validateArray(dataList) ? callList : call}
          />
        </table>
      </div>
    </>
  )
}

const TableBody = (props) => {
  const { pathname } = useLocation()
  const { openDialog, dialogClose } = useDialog()
  const { data, header, onClick, call, handleRegister } = props

  function handleReview(head, item) {
    openDialog(
      <>
        <FormReview
          item={item}
          head={head}
          onClose={dialogClose}
          type={pathname.includes('review') ? 'review' : 'answer'}
        />
        <br />
      </>,
    )
  }

  return (
    <tbody>
      {validateArray(data)
        ? data.map((item, index) => (
            <tr key={index} className="bg-white border-b">
              <ItemContent>
                {validateArray(header)
                  ? header.map((head, j) =>
                      j === 0 ? (
                        <th
                          key={j}
                          scope="row"
                          onClick={() => onClick(item, call)}
                          className="sticky left-0 p-1 font-medium text-gray-900 whitespace-nowrap bg-[#f0f0f0] border border-gray-300"
                        >
                          {item[head.name]}
                        </th>
                      ) : head.type === 'actionCell' ? (
                        <td
                          key={j}
                          className="p-1 border border-gray-300 relative"
                          onClick={() => onClick(item, call)}
                        >
                          <span>{item[head.name]}</span>
                          {/* <Button className="px-1 py-0 absolute right-0 top-0 text-xs">
                          <i className="fas fa-pencil-alt"></i>
                        </Button> */}
                        </td>
                      ) : head.type !== 'action' ? (
                        <td key={j} className="p-1 border border-gray-300">
                          {head.formClick ? (
                            <div
                              onClick={() => onClick(item, call)}
                              className=""
                            >
                              {item[head.name] ? item[head.name] : '-'}
                            </div>
                          ) : pathname.includes('review') ||
                            pathname.includes('answer') ? (
                            <div className="group flex justify-between">
                              <span className="py-1">
                                {item[head.name] ? item[head.name] : '-'}
                              </span>
                              <Button
                                onClick={() => handleReview(head, item)}
                                className="px-1 py-0 text-base hidden group-hover:block"
                              >
                                <i className="fas fa-question-circle"></i>
                              </Button>
                            </div>
                          ) : (
                            <InputCell
                              item={item}
                              head={head}
                              call={call}
                              name={head.name}
                              data={item[head.name]}
                              handleRegister={handleRegister}
                            />
                          )}
                        </td>
                      ) : (
                        <td
                          key={j}
                          className="flex gap-1 px-1 sticky right-0 bg-[#f0f0f0] text-center"
                        >
                          {validateArray(head.actions)
                            ? head.actions.map((act, index) => (
                                <div
                                  key={index}
                                  className={`p-1 rounded-sm cursor-pointer ${act.color} ${act.bgColor}`}
                                  onClick={() => act.action(item, call)}
                                >
                                  <Tooltip
                                    title={act.label}
                                    position="right"
                                    trigger="mouseenter"
                                  >
                                    <i className={act.icon}></i>
                                  </Tooltip>
                                </div>
                              ))
                            : null}
                        </td>
                      ),
                    )
                  : null}
              </ItemContent>
            </tr>
          ))
        : null}
    </tbody>
  )
}

const ItemContent = ({ children }) => {
  // const { data, loading, call } = Request({
  //   urlApi: `/api/Item/{itemId}/itemFieldName/{itemFieldName}`,
  // })
  return <>{children}</>
}

const FormReview = ({ item, head, onClose, type }) => {
  const { data, loading } = Request({
    urlApi: `/Item/${item.id}/itemFieldName/${head.name}`,
  })

  function onSubmit(values) {
    requestAuth('post', `/Item`, {
      ...values,
      itemId: item.id,
      itemFieldName: head.name,
      type: type === 'answer' ? type : 'question',
    })
      .then(() => {
        onClose()
        toast.success('Datos extraídos')
      })
      .catch(() => {
        toast.error('No se pudo extraer los datos')
      })
  }

  return (
    <>
      <H3>{head.label}</H3>
      {data?.obsList?.length > 0
        ? data?.obsList?.map((obs, index) => (
            <H3 key={index} className="text-start">
              {obs.type === 'answer' ? 'Respuesta:' : 'Comentario:'}{' '}
              {obs.description}
            </H3>
          ))
        : null}
      <FormDefault
        fields={[
          {
            label: type === 'answer' ? 'Respuesta' : 'Comentario',
            name: 'description',
            type: 'textArea',
          },
        ]}
        initialValues={{
          description: '',
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Campo requerido.'),
        })}
        onSubmit={onSubmit}
        buttonName="Extraer"
      />
    </>
  )
}

const InputCell = (props) => {
  const { data, handleRegister, item, name, call, head } = props
  const [input, setInput] = useState(data)
  const [view, setView] = useState(true)

  function handleInput(e) {
    setInput(e.target.value)
  }

  function handleCancel() {
    setView(!view)
    setInput(data)
  }

  function handleReload() {
    setView(!view)
    call()
  }

  if (view) {
    return (
      <div className="" onClick={() => setView(!view)}>
        {data ? data : '-'}
      </div>
    )
  } else {
    return (
      <form className="flex gap-1">
        {head.typeInput === 'select' ? (
          <Select field={head} handleInput={handleInput} input={input} />
        ) : (
          <input
            className="w-full min-w-[120px] rounded-md border border-gray-400 focus:outline-none"
            type="text"
            name={name}
            value={input}
            onChange={handleInput}
          />
        )}
        <div
          className={`p-1 rounded-sm cursor-pointer text-[#1d4ed8] bg-[#1d4fd842]`}
          onClick={() => handleRegister(name, input, item, handleReload)}
        >
          <Tooltip title="Guardar" position="right" trigger="mouseenter">
            <i className="fa-solid fa-check"></i>
          </Tooltip>
        </div>
        <div
          className={`p-1 rounded-sm cursor-pointer text-red-500 bg-red-300`}
          // onClick={() => act.action(item, call)}
          onClick={handleCancel}
        >
          <Tooltip title="Cancelar" position="right" trigger="mouseenter">
            <i className="fas fa-times"></i>
          </Tooltip>
        </div>
      </form>
    )
  }
}
const Select = ({ field, input, handleInput }) => {
  const { data, call } = Request({
    urlApi: field.urlApi ? `${field.urlApi}` : null,
  })

  return (
    <select className="" value={input} onChange={handleInput}>
      <option value=""></option>
      {data?.map((option, key) => (
        <option key={key} value={option[field.value]}>
          {option[field.labelOption]}
        </option>
      ))}
      {/* <option value="grapefruit">Grapefruit</option>
      <option value="lime">Lime</option>
      <option value="coconut">Coconut</option>
      <option value="mango">Mango</option> */}
    </select>
  )
}

export default GetTable
