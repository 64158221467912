import React, { useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from 'react-chartjs-2'
import faker from 'faker'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export const App = ({
  tramit,
  backgroundColorRGB,
  label,
  value,
  setInfo,
  Info,
  labelGrafic,
}) => {
  const [InfoLabels, setInfoLabels] = useState([])

  const labels = InfoLabels

  const chartRef = useRef()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Bar Chart',
      // },
    },
  }

  const data = {
    labels: labels.map((e) => e[label]),
    datasets: [
      {
        label: `${labelGrafic} `,
        data: labels.map((e) => e[value]),
        backgroundColor: `rgba(${backgroundColorRGB})`,
      },
    ],
  }
  function info() {
    let array = []
    if (tramit.length > 0) tramit?.map((e) => array.push(e))
    setInfoLabels(array)
  }
  useEffect(() => {
    info()
  }, [tramit])

  const printElementAtEvent = (element) => {
    if (!element.length) return

    const { datasetIndex, index } = element[0]
    if (setInfo)
      setInfo(data.labels[index], data.datasets[datasetIndex].data[index])
  }

  const onClickChart = (event) => {
    // onClick(true)
    const { current: chart } = chartRef

    if (!chart) {
      return
    }
    printElementAtEvent(getElementAtEvent(chart, event))
  }
  return (
    <Bar
      ref={chartRef}
      onClick={onClickChart}
      options={options}
      data={data}
      type="bar"
    />
  )
}
