import { useState } from 'react'
import { H3, Label } from '../Text'
import { toast } from 'react-toastify'
import Circle from '../Animation/Circle'
import { Button } from '../Buttons'
import { Request } from '../../hooks/Request'
import FormDefault from '../Forms/FormDefault'
import TableComplete from '../Tables/TableComplete'
import { requestAuth } from '../services/RequestService'
import { useDialog } from '../../contexts/DialogContext'
import { DialogConfirmation } from '../Modal'

const InputSubData = ({ field }) => {
  const { openDialog, dialogClose } = useDialog()

  const { data, loading, call } = Request({
    urlApi: `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
  })
  const { data: document, loading: loadDocument } = Request({
    urlApi: field.urlApi,
  })

  function handleForm(item) {
    openDialog(
      <>
        {loadDocument && (
          <FormAddDocument
            data={document}
            item={item}
            reload={call}
            field={field}
            onClose={dialogClose}
          />
        )}
        <br />
      </>,
    )
  }

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar este item?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/dataSet/procedure/subData/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />,
    )
  }

  return (
    <div>
      <Label htmlFor={field.name} className={`${field.className} my-0 mt-4`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <div className="bg-slate-50 rounded-lg p-3 pt-2">
        {loadDocument && (
          <FormAddDocument
            data={document}
            reload={call}
            field={field}
            onClose={() => {}}
          />
        )}
      </div>
      <br />
      {!loading ? (
        <Circle />
      ) : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: 'numberValue',
                label: 'Cantidad (Bs.)',
              },
              {
                name: 'label',
                label: 'Item',
              },
              {
                name: 'add',
                type: 'action',
                label: 'Opciones',
                sticky: true,
                actions: [
                  {
                    label: 'Editar',
                    icon: 'fas  fa-edit',
                    action: handleForm,
                    color: 'text-[#1d4ed8]',
                  },
                  {
                    label: 'Añadir',
                    icon: 'fas fa-trash',
                    action: handleDelete,
                    color: 'text-red-500',
                  },
                ],
              },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  )
}

const FormAddDocument = ({ data, item = null, reload, field, onClose }) => {
  const [values, setValues] = useState({
    Label: item?.label ? item.label : `${data[0].name}}`,
    NumberValue: item?.numberValue ? item.numberValue : '',
    Description: item?.description ? item.description : '',
  })

  async function handleSubmit() {
    await requestAuth(
      item ? 'put' : 'post',
      item
        ? `/dataSet/procedure/subData/${item.id}`
        : `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
      values,
    )
      .then(() => {
        reload()
        onClose()
        setValues({
          Label: `${data[0].type} - ${data[0].description}`,
          NumberValue: '',
          Description: '',
        })
        toast.success('Registro exitoso')
      })
      .catch(() => {
        toast.error('Se produjo un error al guardar')
      })
  }

  function handleChange(e) {
    const { name, value } = e.target
    setValues((a) => ({
      ...a,
      [name]: value,
    }))
  }

  return (
    <form className="flex flex-col bg-gray-300/40 p-2 rounded-md">
      <Label className={`mt-2`}>Lista de items</Label>
      <select
        value={values.Label}
        className="p-2 rounded-md"
        name="Label"
        onChange={handleChange}
      >
        {data.map((option, key) => (
          <option key={key} value={`${option.name}`}>
            {option.name}
          </option>
        ))}
      </select>
      <Label className={`my-0 mt-4`}>Cantidad</Label>
      <div className="flex gap-2">
        <input
          value={values.NumberValue}
          onChange={handleChange}
          className="w-full p-2 rounded-md"
          type="text"
          name="NumberValue"
          placeholder='cantidad'
        />
        <Button onClick={handleSubmit} className="px-3 py-0">
          Adicionar
        </Button>
      </div>
    </form>
  )

  //   return (
  //     <FormDefault
  //       fields={[
  //         {
  //           label: 'Lista de items',
  //           name: 'Label',
  //           type: 'select',
  //           typeInput: 'select',
  //           options: data.map((item) => ({
  //             label: `${item.type} - ${item.description}`,
  //             value: item.id,
  //           })),
  //         },
  //         {
  //           label: 'Cantidad',
  //           name: 'NumberValue',
  //         },
  //       ]}
  //       initialValues={{
  //         description: '',
  //       }}
  //       validationSchema={null}
  //       // validationSchema={Yup.object().shape(
  //       //   field.fieldId === 233
  //       //     ? {
  //       //         url: Yup.mixed().when('tariffItem', {
  //       //           is: '0',
  //       //           then: Yup.mixed().required('Campo requerido.'),
  //       //         }),
  //       //         description: Yup.string().when('tariffItem', {
  //       //           is: '0',
  //       //           then: Yup.string().required('Campo requerido.'),
  //       //         }),
  //       //       }
  //       //     : {
  //       //         url: Yup.mixed().required('Campo requerido.').nullable(),
  //       //       },
  //       // )}
  //       onSubmit={handleSubmit}
  //       buttonName="Adicionar"
  //     />
  //   )
}

export default InputSubData
