import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { H1 } from '../../components/Text'
import { Request } from '../../hooks/Request'
import ListDocument from '../../components/common/ListDocument'
import { useDialog } from '../../contexts/DialogContext'
import { useModal } from '../../contexts/ModalContext'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import RenderLiquidaciónProforma from '../../containers/ManegeProcedure/RenderLiquidaciónProforma'
import { FillForm } from '../../containers/steps'

const ProformRegister = ({ rol }) => {
  const { openModal, onClose } = useModal()
  const { openDialog } = useDialog()
  function handleProcedure(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={{
          ...procedure,
          processStepId: rol === 'accountant' ? 16 : 5,
        }}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rol === 'accountant' ? '11' : '10'}
        step={rol === 'accountant' ? '4' : '3'}
        typeId="1"
      />,
    )
  }

  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />)
  }

  return (
    <CallServiceTable
      urlApi="/procedure/openprocedures"
      addFilters={[
        {
          name: 'procedureNumber',
          label: 'Tramite',
          filter: true,
        },
        {
          name: 'internCode',
          label: 'Nro. interno',
          filter: true,
        },
        {
          name: 'clientCode',
          label: 'Cod. Cliente',
          filter: true,
        },
        {
          name: 'dimNumber',
          label: 'Nro. DIM/DEX',
          filter: true,
        },
        {
          name: 'invoice',
          label: 'Factura',
          filter: true,
        },
        {
          name: 'docEmbarque',
          label: 'Embarque',
          filter: true,
        },
      ]}
      filters={[
        {
          name: 'companyId',
          url: '/company/getallcompanies',
          default: 'Todas las compañías',
          valueOption: 'id',
          labelOption: 'razonSocial',
        },
      ]}
      header={[
        {
          name: 'numberString',
          label: 'Trámite',
        },
        {
          name: 'internCode',
          label: 'Nro. interno',
        },
        {
          name: 'nroCliente',
          label: 'Cod. Cliente',
        },
        {
          name: 'dimNumber',
          label: 'Nro. DIM/DEX',
        },
        {
          name: 'nroEmbarque',
          label: 'Embarque',
        },
        {
          name: 'billNumber',
          label: 'Factura',
        },
        {
          name: 'currentStepName',
          label: 'Paso actual',
        },
        {
          name: 'currentStep',
          label: 'Progreso',
          type: 'custom',
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return 'Terminado'
            }
            return value
          },
        },
        {
          name: 'companyName',
          label: 'Cliente',
        },
        {
          name: 'customsClearance',
          label: 'Aduana',
        },
        {
          name: 'canalColor',
          label: 'Canal',
        },
        {
          name: 'procedureTypeName',
          label: 'Patrón',
        },
        {
          name: 'creationDate',
          label: 'Fecha de inicio',
          type: 'date',
        },
        {
          name: 'Reports',
          type: 'action',
          label: 'Reportes',
          sticky: true,
          actions: [
            {
              label: 'Generar liquidación - proforma',
              icon: 'fas fa-coins',
              action: generateSettlementProforma,
              color: 'text-[#1d4ed8]',
            },
          ],
        },
      ]}
      addUrl="status=pending"
      onClick={handleProcedure}
      statusPaination={true}
    />
  )
}

export default ProformRegister
