import { useState, useEffect } from 'react'
import { H3, Label } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { Request } from '../../hooks/Request'
import { Circle } from '../../components/Animation'
import { validateArray } from '../../utils/validate'
import TableComplete from '../../components/Tables/TableComplete'
import FileStatus from './FileStatus'
import { requestAuth } from '../../components/services/RequestService'
import { toast } from 'react-toastify'

const Documents = ({ procedure, guide }) => {
  const { data, loading, call } = Request({
    urlApi: `/field/uploadedfileFields/${procedure.id}`,
  })

  return (
    <div>
      <FormDocument procedureId={procedure.id} reload={call} guide={guide} />
      {/* <H3 className="font-medium mt-4 mb-1 text-xl text-start mx-auto">
        Documentos del tramite
      </H3> */}
      {/* {!loading ? (
        <Circle />
      ) : (
        <ListDocument data={data} procedure={procedure} />
      )} */}
    </div>
  )
}

const FormDocument = ({ procedureId, reload, guide }) => {
  const [load, setload] = useState(false)
  const [values, setValues] = useState({
    FieldId: '',
    File: '',
    Description: '',
    field: null,
  })

  const { data, loading } = Request({
    urlApi: `/field/fileFields`,
  })

  function handleChange(e) {
    const { name, value } = e.target
    if (name === 'FieldId') {
      setValues((a) => ({
        ...a,
        [name]: value,
        field: data.filter((field) => field.id + '' === value)[0],
      }))
    } else {
      setValues((a) => ({
        ...a,
        [name]: value,
      }))
    }
  }

  function handleFile(e) {
    const { name, files } = e.target
    setValues((a) => ({
      ...a,
      [name]: files[0],
    }))
  }

  async function handleSubmit() {
    setload(true)
    let formData = new FormData()
    formData.append('Name', values.FieldId)
    formData.append('File', values.File)
    formData.append('Status', 'verified')
    formData.append('ProcedureId', procedureId)
    const fileContent = await requestAuth('post', '/files', formData)
      .then((res) => {
        return {
          Value: res.data.url,
          ProcedureId: procedureId,
          FieldId: parseInt(values.FieldId),
          FileId: res.data.id,
        }
      })
      .catch((error) => {
        setload(false)
        toast.error(
          error.response.data
            ? error.response.data
            : 'Se produjo un error al subir el archivo',
        )
      })
    submitDataset(fileContent)
  }

  async function submitDataset(value) {
    await requestAuth('post', '/dataSet/NewDataSet', [value])
      .then(() => {
        setload(false)
        reload()
        toast.success('El archivo se subió exitosamente')
      })
      .catch(() => {
        setload(false)
        toast.error('No se pudo subir el archivo')
      })
  }

  if (!loading) {
    return <Circle />
  }
  return (
    <div className="bg-slate-50 p-2 mb-2 rounded-md">
      {/* <Label className="my-0 mt-4">Subir documento</Label> */}
      <form className="flex flex-col">
        <Label className={`mt-2`}>Lista de documentos</Label>
        <select
          value={values.FieldId}
          className="p-2 rounded-md"
          name="FieldId"
          onChange={handleChange}
        >
          <option value="">Seleccione un documento</option>
          {data.map((option, key) => (
            <option key={key} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>

        {!values.field ? null : values.field.type !== 'fileStatus' ? (
          <>
            <Label className={`my-0 mt-4`}>Archivo</Label>
            <div className="flex gap-2">
              <div
                className="w-full rounded-[7px] placeholder-black placeholder:text-[15px]
                placeholder:opacity-50 placeholder:font-normal
                px-[17px] py-1 font-light
                text-[15px] bg-white/50 md:bg-white/75"
              >
                <input
                  // value={values.File}
                  onChange={handleFile}
                  className="text-sm text-grey-500
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-0
                  file:text-sm file:font-medium
                  file:bg-[#1d4ed8] file:text-white
                  hover:file:cursor-pointer hover:file:bg-[#1d4fd8ce]"
                  type="file"
                  name="File"
                />
              </div>
              {load ? (
                <Circle />
              ) : (
                <Button onClick={handleSubmit} className="px-3 py-0">
                  Adicionar
                </Button>
              )}
            </div>
          </>
        ) : (
          <FileStatus
            field={{
              ...values.field,
              id: procedureId,
              fieldId: values.field?.id,
            }}
            guide={guide}
          />
        )}
      </form>
    </div>
  )
}

const ListDocument = ({ data, procedure }) => {
  const [listFile, setListFile] = useState([])
  const [load, setLoad] = useState(true)
  // const { data, loading } = Request({
  //   urlApi: `/field/uploadedfileFields/${procedure.id}`,
  // })

  useEffect(() => {
    async function handleBreakDown(list) {
      setListFile([])
      setLoad(true)
      await Promise.all(
        list.map(async (item) => {
          if (item.field.type === 'fileStatus') {
            const fileContents = await requestAuth(
              'get',
              `/fileState/procedure/${procedure.id}/field/${item.field.id}`,
            ).then((res) => {
              return res.data
            })
            console.log(fileContents)
            setListFile((a) => [...a, ...fileContents])
          } else {
            setListFile((a) => [
              ...a,
              { ...item, url: item.value, name: item.field.label },
            ])
          }
        }),
      )
      setLoad(false)
    }
    // if (loading) {
    handleBreakDown(data)
    // }
  }, [data])

  return (
    <div>
      {validateArray(data) && !load ? (
        <TableComplete
          header={[
            {
              name: 'name',
              label: 'Documento',
            },
            {
              name: 'url',
              label: 'Enlace',
              type: 'custom',
              builder: (item) => {
                if (item) {
                  return (
                    <a
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </a>
                  )
                } else {
                  return '-'
                }
              },
            },
            {
              name: 'number',
              label: 'Nro',
            },

            {
              label: 'Fecha',
              name: 'creationDate',
              type: 'date',
            },
            {
              name: 'presentation',
              label: 'Presentación',
            },
            {
              name: 'status',
              label: 'Estado',
            },
            {
              label: 'Proveedor',
              name: 'provider',
            },
          ]}
          data={listFile}
        />
      ) : null}
    </div>
  )
}

// http://localhost:5000/api/field/fileFields
// http://localhost:5000/api/field/uploadedfileFields/13

export default Documents
