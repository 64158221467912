import { requestAuth } from '../services/RequestService'
import { Button } from '../Buttons'
import { useState } from 'react'
import { toast } from 'react-toastify'

const DialogConfirmation = (props) => {
  const {
    onClose,
    children,
    method = 'put',
    url,
    data = {},
    handleSuccess = () => { },
    texBtn = 'Generar',
  } = props
  const [load, setload] = useState(false)

  async function handleOnSubmit() {
    setload(true)
    await requestAuth(method, url, data)
      .then((res) => {
        setload(false)
        toast.success('Se guardaron los cambios')
        onClose()
        handleSuccess(res.data)
      })
      .catch((err) => {
        setload(false)
        console.log(err)
        // toast.error('No se pudo guardar')
        toast.error(
          <span>{
            err?.response?.data?.message}
            {
              err?.response?.data?.file &&
              <>
                <br />
                <a href={err?.response?.data?.file} target='_blank' rel='noreferrer' className='text-blue-400'>Abrir archivo</a>
              </>
            }
          </span>
          ||
          'No se pudo guardar'
        )
      })
  }

  return (
    <div>
      {children}
      <div className="flex gap-4 m-4">
        {load ? (
          <div className="w-full h-[100px] flex justify-center items-center">
            <svg className=" animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
              <i className="text-white font-bold fa-solid fa-circle-notch "></i>
            </svg>
          </div>
        ) : (
          <>
            <Button
              onClick={onClose}
              className="w-full bg-[#fff] text-[#FBCE00] border-2 border-[#FBCE00]"
            >
              Cancelar
            </Button>
            <Button onClick={handleOnSubmit} className="w-full">
              {texBtn}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default DialogConfirmation
