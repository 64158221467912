import * as Yup from "yup";
import { toast } from "react-toastify";
import { Anchor } from "../../components/Text";
import Button from "../../components/Buttons/Button";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import CompanyInfo from "../../containers/ModalsCompany/Info";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const Company = () => {
  const { openModal, onClose } = useModal();

  function handleGetCompany(company, tableReload) {
    openModal(
      <CompanyInfo company={company} reload={tableReload} onClose={onClose} />
    );
  }

  function handleNewCompany(tableReload) {
    openModal(<FormNewCompany reload={tableReload} onClose={onClose} />);
  }

  function handleImport(tableReload) {
    openModal(<FormImport reload={tableReload} onClose={onClose} />);
  }

  exitEsc(onClose);
  return (
    <>
      <CallServiceTable
        onClick={handleGetCompany}
        urlApi="/company"
        component={({ reload }) => {
          return (
            <>
              <Button onClick={() => handleImport(reload)}>
                Importar <i className="fas fa-cloud-download-alt"></i>
              </Button>
              <Button onClick={() => handleNewCompany(reload)}>
                Crear compañía <i className="fa-solid fa-plus"></i>
              </Button>
            </>
          );
        }}
        header={[
          {
            name: "razonSocial",
            label: "Compañía",
            filter: true,
          },
          {
            name: "nit",
            label: "NIT",
            filter: true,
          },
          {
            name: "status",
            label: "Estado",
            filter: false,
          },
        ]}
        addUrl={`userId=${localStorage.getItem("id")}`}
        showTable={false}
      />
    </>
  );
};

const FormNewCompany = (props) => {
  const { onClose, reload } = props;
  return (
    <div>
      <FormDefault
        tittle="Crear nueva compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "num",
            placeholder: "Introduzca id de compañia (opcional)",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "nit",
            placeholder: "Introduzca su nùmero de NIT",
            type: "number",
            typeInput: "input",
          },

          {
            label: "Razon social",
            name: "razonSocial",
            placeholder: "Introdusca la razòn social",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "email",
            placeholder: "Introdusca el correo electrónico",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          nit: "",
          razonSocial: "",
        }}
        validationSchema={Yup.object().shape({
          nit: Yup.string().required("Este campo es obligatorio"),
          razonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", "company/Register", values)
            .then(() => {
              reload();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se ha producido un error al enviar"
              );
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

const FormImport = (props) => {
  const { reload, onClose } = props;
  function onSubmit(values) {
    let formData = new FormData();
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    requestAuth("post", "/Company/reader", formData)
      .then(() => {
        reload();
        onClose();
        toast.success("Datos extraídos");
      })
      .catch(() => {
        toast.error("No se pudo extraer los datos");
      });
  }

  return (
    <div>
      <FormDefault
        tittle="Formulario para importar clientes"
        bonusContent={
          <>
            <span>
              <Anchor
                position="left"
                className="px-1.5 py-1"
                style={{
                  background: "gray",
                  color: "white",
                  borderRadius: "5px",
                  hover: {
                    color: "red",
                  },
                }}
                href="https://res.cloudinary.com/wmcgi/raw/upload/v1678978358/trazo/templates/clientes_activos_nz4ghz.xlsx"
              >
                Descargar plantilla
              </Anchor>
            </span>
            <div
              style={{
                color: "gray",
                padding: "10px",
                background: "white",
                borderRadius: "5px",
                fontSize: "13px",
                textAlign: "justify",
              }}
            >
              <ul style={{ listStyle: "square inside" }}>
                <b>
                  Pasos para la importación de compañias a través de un archivo
                  excel:
                </b>
                <li>
                  Pulsar el botón <b>Descargar plantilla</b>.
                </li>
                <li>Llenar la plantilla con los datos de las compañias.</li>
                <li>
                  Una vez llenada la plantilla excel con los datos necesarios,
                  pulsar el botón <b>Examinar...</b>
                </li>
                <li>Seleccionar la plantilla editada.</li>
                <li>
                  Pulsar el botón <b>Importar</b>.
                </li>
              </ul>
            </div>
          </>
        }
        fields={[
          {
            label: "Adjuntar archivo excel",
            name: "File",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          File: null,
        }}
        validationSchema={Yup.object().shape({
          File: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={onSubmit}
        buttonName="Importar"
      />
    </div>
  );
};

const exitEsc = (setClose) => {
  document.addEventListener("keydown", (event) => {
    if (event.which === 27 && event.keyCode === 27) {
      setClose();
    }
  });
};

export default Company;
