import { useState } from "react";
import { H3 } from "../../components/Text";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { airlineField } from "../../utils/fieldForm";
import { requestAuth } from "../../components/services/RequestService";

const DropdownManagement = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createDropdown(reload) {
    openModal(<FormDropdown reload={reload} onClose={onClose} />);
  }

  function editDropdown(dropdown, reload) {
    openModal(
      <FormDropdown dropdown={dropdown} reload={reload} onClose={onClose} />
    );
  }

  function deleteDropdown(dropdown, reload) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres eliminar la lista de opciones?</H3>
        }
        onClose={dialogClose}
        method="delete"
        url={`/Dropdown/List/${dropdown.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function viewDropdown(dropdown) {
    openModal(
      <ListOption dropdown={dropdown} onClose={onClose} openModal={openModal} />
    );
  }

  return (
    <CallServiceTable
      onClick={viewDropdown}
      component={({ reload }) => {
        return (
          <Button onClick={() => createDropdown(reload)}>
            Crear nueva lista <i className="fa-solid fa-plus"></i>
          </Button>
        );
      }}
      urlApi="/Dropdown/List"
      header={[
        {
          name: "name",
          label: "Nombre",
          filter: true,
        },
        {
          name: "category",
          label: "Categoría",
          filter: true,
        },
        {
          name: "description",
          label: "Descripción",
          filter: false,
        },
        {
          name: "edit",
          type: "action",
          label: "Editar",
          actions: [
            {
              label: "Editar",
              icon: "fas fa-edit",
              action: editDropdown,
              color: "text-[#1d4ed8]",
            },
          ],
        },
        {
          name: "delete",
          type: "action",
          label: "Eliminar",
          actions: [
            {
              label: "Eliminar",
              icon: "fas fa-trash",
              action: deleteDropdown,
              color: "text-red-500",
            },
          ],
        },
      ]}
    />
  );
};

const ListOption = (props) => {
  const { dropdown, openModal } = props;
  const { openDialog, dialogClose } = useDialog();

  function createOption(reload) {
    openDialog(
      <FormOption dropdown={dropdown} reload={reload} onClose={dialogClose} />
    );
  }
  function editOption(option, reload) {
    openDialog(
      <FormOption
        option={option}
        dropdown={dropdown}
        reload={reload}
        onClose={dialogClose}
      />
    );
  }

  function handleSubList(option) {
    openModal(<ViewSubList {...props} option={option} />);
  }

  function deleteOption(option, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar la opción?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Dropdown/Option/${option.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  return (
    <>
      <H3>{dropdown.name}</H3>
      <CallServiceTable
        component={({ reload }) => {
          return (
            <Button onClick={() => createOption(reload)}>
              <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        urlApi={`/Dropdown/${dropdown.urlLabel}/options`}
        header={[
          {
            name: "name",
            label: "Nombre",
          },
          {
            name: "type",
            label: "Tipo",
          },
          {
            name: "data",
            label: "Dato",
            type: "custom",
            builder: (value, _, data) => {
              if (data.type === "Aerolínea") {
                const dataJson = JSON.parse(data.data);
                const Text = ({ title, children }) => {
                  return (
                    <h3 className="whitespace-nowrap text-sm">
                      <strong>{title}:</strong> {children}
                    </h3>
                  );
                };
                return (
                  <div className="">
                    <Text title="Monto">{dataJson.amount}</Text>
                    <Text title="Tipo de pago">{dataJson.pay}</Text>
                    <Text title="N° de cuenta">{dataJson.account}</Text>
                    <Text title="Banco">{dataJson.bank}</Text>
                    <Text title="Titular de la cuenta">
                      {dataJson.headline}
                    </Text>
                    <Text title="NIT/CI">{dataJson.citCi}</Text>
                    <Text title="Correo">{dataJson.email}</Text>
                    <Text title="Contacto">{dataJson.contact}</Text>
                    <Text title="Días de arribo">{dataJson.day}</Text>
                  </div>
                );
              } else if (data.type === "SubLista") {
                console.log(data);
                return (
                  <div
                    onClick={() => handleSubList(data)}
                    className="cursor-pointer text-[#1d4ed8]"
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                );
              }
              return value;
            },
          },

          {
            name: "Action",
            type: "action",
            label: "Acciones",
            sticky: true,
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editOption,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteOption,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </>
  );
};
const FormOption = (props) => {
  const { option, dropdown, onClose, reload } = props;
  let initialFields =
    option && option.type === "Aerolínea" ? JSON.parse(option.data) : {};
  return (
    <>
      <FormDefault
        tittle="Crear nueva opción"
        fields={[
          {
            label: "Nombre de la opción",
            name: "name",
            placeholder: "Introduzca su nombre de la lista",
          },
          {
            label: "Tipo de opción",
            name: "type",
            placeholder: "Introduzca su nombre de la lista",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Simple",
                value: "Simple",
              },
              {
                label: "Texto",
                value: "Texto",
              },
              {
                label: "SubLista",
                value: "SubLista",
              },
              {
                label: "Código",
                value: "Código",
              },
              {
                label: "Aerolínea",
                value: "Aerolínea",
              },
            ],
          },
          {
            label: "Dato",
            name: "data",
            placeholder: "Introduzca su nombre de la lista",
            reference: "type",
            condition: "Texto,Código",
          },
          ...airlineField,
        ]}
        initialValues={{
          name: option?.name ?? "",
          type: option?.type ?? "Simple",
          data: option?.type !== "Aerolínea" ? option?.data ?? "" : "",
          ...initialFields,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          const { name, type, data, ...rest } = values;
          let auxData = data;
          if (type === "Aerolínea") {
            auxData = JSON.stringify(rest);
          }
          await requestAuth(
            option ? "put" : "post",
            option ? `/Dropdown/Option/${option.id}` : "/Dropdown/Option",
            {
              ...values,
              data: auxData,
              DropdownListLabel: dropdown.urlLabel,
            }
          )
            .then(() => {
              onClose();
              reload();
              toast.success("Se creo con éxito");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se produjo un error al crear"
              );
            });
        }}
        buttonName="Crear"
      />
      <br />
    </>
  );
};

const FormDropdown = (props) => {
  const { dropdown, onClose, reload } = props;
  return (
    <FormDefault
      tittle={dropdown ? "Editar lista" : "Crear nueva lista"}
      fields={[
        {
          label: "Nombre de la lista",
          name: "name",
          placeholder: "Introduzca su nombre de la lista",
        },
        {
          label: "Palabra clave",
          name: "urlLabel",
          placeholder: "Introduzca una palabra clave",
        },
        {
          label: "Categoría",
          name: "category",
          placeholder: "Introduzca una categoría",
        },
        {
          label: "Descripción",
          name: "description",
          placeholder: "Introduzca la descripción de la lista de la lista",
        },
      ]}
      initialValues={{
        name: dropdown?.name ?? "",
        urlLabel: dropdown?.urlLabel ?? "",
        category: dropdown?.category ?? "",
        description: dropdown?.description ?? "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Este campo es obligatorio"),
        urlLabel: Yup.string().required("Este campo es obligatorio"),
      })}
      onSubmit={async (values) => {
        await requestAuth(
          dropdown ? "put" : "post",
          dropdown ? `/Dropdown/List/${dropdown.id}` : "/Dropdown/List",
          {
            ...values,
            active: true,
          }
        )
          .then(() => {
            onClose();
            reload();
            toast.success("Se creo con éxito");
          })
          .catch((response) => {
            toast.error(
              response.response.data
                ? response.response.data
                : "Se produjo un error al crear"
            );
          });
      }}
      buttonName={dropdown ? "Editar" : "Crear"}
    />
  );
};

const ViewSubList = (props) => {
  const { option, dropdown, openModal } = props;
  const { openDialog, dialogClose } = useDialog();
  console.log(option);
  function viewOptions() {
    openModal(<ListOption {...props} />);
  }
  function createSubDropdown(reload) {
    openDialog(
      <CallServiceTable
        onClick={(dropSelect) => addDropdown(dropSelect, reload)}
        urlApi="/Dropdown/List"
        header={[
          {
            name: "name",
            label: "Nombre",
            filter: true,
          },
          {
            name: "description",
            label: "Descripción",
          },
        ]}
      />
    );
  }
  async function addDropdown(dropSelect, reload) {
    openDialog(<Circle />);
    await requestAuth(
      "post",
      `/dropdown/option/${option.id}/subList/${dropSelect.id}`
    )
      .then(() => {
        reload();
        dialogClose();
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }
  // function editSubDropdown() {}
  function deleteSubDropdown() {}

  function viewOptionSubDropdown(subDropdown, reload) {
    openDialog(
      <ViewOptionSubDropDown
        {...props}
        subDropdown={subDropdown}
        reload={reload}
      />
    );
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <Button className="px-3 py-2" onClick={viewOptions}>
          <i className="fas fa-angle-left"></i>
        </Button>
        <H3 className="text-2xl">{option.name}</H3>
      </div>
      <CallServiceTable
        onClick={viewOptionSubDropdown}
        component={({ reload }) => {
          return (
            <Button onClick={() => createSubDropdown(reload)}>
              <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        urlApi={`/dropdown/option/${option.id}/subList`}
        header={[
          {
            name: "name",
            label: "Nombre",
          },
          {
            name: "description",
            label: "Descripción",
          },
          {
            name: "Action",
            type: "action",
            label: "Acciones",
            sticky: true,
            actions: [
              // {
              //   label: "Editar",
              //   icon: "fas fa-edit",
              //   action: editSubDropdown,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteSubDropdown,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const ViewOptionSubDropDown = (props) => {
  const { subDropdown, reload, openModal } = props;
  const { openDialog, dialogClose } = useDialog();
  const [load, setLoad] = useState(false);

  function handleReload() {
    reload();
    setLoad(!load);
  }
  function handleSubList(option) {
    openModal(<ViewSubList {...props} option={option} />);
  }

  return (
    <div>
      <FormOption
        dropdown={subDropdown}
        reload={handleReload}
        onClose={() => {}}
      />
      <CallServiceTable
        reload={load}
        // component={({ reload }) => {
        //   return (
        //     <Button onClick={() => createOption(reload)}>
        //       <i className="fa-solid fa-plus"></i>
        //     </Button>
        //   );
        // }}
        urlApi={`/Dropdown/${subDropdown.urlLabel}/options`}
        header={[
          {
            name: "name",
            label: "Nombre",
          },
          {
            name: "type",
            label: "Tipo",
          },
          {
            name: "data",
            label: "Dato",
            type: "custom",
            builder: (value, _, data) => {
              if (data.type === "Aerolínea") {
                const dataJson = JSON.parse(data.data);
                const Text = ({ title, children }) => {
                  return (
                    <h3 className="whitespace-nowrap text-sm">
                      <strong>{title}:</strong> {children}
                    </h3>
                  );
                };
                return (
                  <div className="">
                    <Text title="Monto">{dataJson.amount}</Text>
                    <Text title="Tipo de pago">{dataJson.pay}</Text>
                    <Text title="N° de cuenta">{dataJson.account}</Text>
                    <Text title="Banco">{dataJson.bank}</Text>
                    <Text title="Titular de la cuenta">
                      {dataJson.headline}
                    </Text>
                    <Text title="NIT/CI">{dataJson.citCi}</Text>
                    <Text title="Correo">{dataJson.email}</Text>
                    <Text title="Contacto">{dataJson.contact}</Text>
                    <Text title="Días de arribo">{dataJson.day}</Text>
                  </div>
                );
              } else if (data.type === "SubLista") {
                console.log(data);
                return (
                  <div
                    onClick={() => handleSubList(data)}
                    className="cursor-pointer text-[#1d4ed8]"
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                );
              }
              return value;
            },
          },
          // {
          //   name: "Action",
          //   type: "action",
          //   label: "Acciones",
          //   sticky: true,
          //   actions: [
          //     {
          //       label: "Editar",
          //       icon: "fas fa-edit",
          //       action: editOption,
          //       color: "text-[#1d4ed8]",
          //     },
          //     {
          //       label: "Eliminar",
          //       icon: "fas fa-trash",
          //       action: deleteOption,
          //       color: "text-red-500",
          //     },
          //   ],
          // },
        ]}
      />
    </div>
  );
};

export default DropdownManagement;
