import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import Texto from "../../components/Text/Texto";
import { H1, Anchor } from "../../components/Text";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import SubModal from "../../components/Modal/SubModal";
import { toast } from "react-toastify";
import Button from "../../components/Buttons/Button";
import * as Yup from "yup";
import ModalFullScreen from "../../components/Modal/ModalFullScreen";

const UserInfo = (props) => {
  const { userId, onClose, reload } = props;
  const { data: userInfo, call } = Request({
    urlApi: userId ? `/users/${userId}/GetUserId` : null,
  });

  const [activateFormEdit, setactivateFormEdit] = useState(false);
  const [activeFormFile, setactiveFormFile] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);
  const [dataFile, setDataFile] = useState();

  const { getuser } = useAuth();

  useEffect(() => {
    if (userId !== undefined) {
      call();
    }
  }, [userId]);

  function getDataFile(value) {
    setDataFile(value);
    setDeleteFile(true);
  }

  exitEsc(onClose);
  exitEsc(setactivateFormEdit);
  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    formData.append("Type", "File");
    formData.append("UserId", userId);
    await requestAuth("post", "/files", formData)
      .then(() => {
        call();
        setactiveFormFile(false);
        toast.success("Envio exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }
  return (
    <>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Nombres:</strong>{" "}
        {userInfo?.userToReturn?.name}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Apellido Paterno:</strong>{" "}
        {userInfo?.userToReturn?.fatherLastName}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Apellido Materno:</strong>{" "}
        {userInfo?.userToReturn?.motherLastName}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Nombre de usuario:</strong>{" "}
        {userInfo?.userToReturn?.username}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Ciudad:</strong>{" "}
        {userInfo?.userToReturn?.city
          ? userInfo?.userToReturn?.city
          : "No registrado"}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Correo:</strong>{" "}
        {userInfo?.userToReturn?.email}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Celular:</strong>{" "}
        {userInfo?.userToReturn?.cellphoneNumber}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Estado:</strong>{" "}
        {userInfo?.userToReturn?.status}
      </Texto>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white flex shadow-xl">
        <Texto className="font-semibold text-black mr-[10px]">Roles: </Texto>
        <ul>
          {userInfo?.rolsUserToReturn.length > 0 ? (
            userInfo?.rolsUserToReturn?.map((rol) => {
              return (
                <li key={rol.id} className=" font-normal">
                  {rol?.label}
                </li>
              );
            })
          ) : (
            <Texto>Sin roles asignados</Texto>
          )}
        </ul>
      </div>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white  shadow-xl">
        <Texto className="font-semibold text-black mr-[10px]">
          Archivos de usuario:{" "}
        </Texto>
        <ul className="w-full">
          {userInfo?.fileListToReturn.length > 0 ? (
            userInfo?.fileListToReturn?.map((file) => {
              return (
                <li
                  key={file.id}
                  className="bg-slate-200 my-2 flex items-center justify-between text-center p-1 pl-4 rounded-lg gap-4"
                  // className="bg-slate-200 my-[10px] flex items-center justify-between text-center px-0"
                >
                  <Anchor
                    className="w-full text-base border-b-2 text-black hover:border-[#1502FF] font-normal hover:text-[#1502FF] duration-200"
                    href={file.url}
                    target="_blanck"
                  >
                    <FontAwesomeIcon
                      className="text-lg mr-2"
                      icon="fa-regular fa-file"
                    />
                    {file?.name}
                  </Anchor>
                  <Button
                    content="Eliminar archivo"
                    className="px-2 py-2"
                    onClick={() => getDataFile(file)}
                  >
                    <FontAwesomeIcon
                      className="text-white text-[18px] sm:text-[20px]"
                      icon="fa-solid fa-trash-can"
                    />
                  </Button>
                </li>
              );
            })
          ) : (
            <Texto>Sin documentación para mostrar</Texto>
          )}
        </ul>
      </div>
      <div className="flex sm:flex-row mt-6 justify-center w-full items-center gap-4">
        <Button
          content="Editar datos del usuario"
          className=""
          onClick={() => {
            call();
            setactivateFormEdit(true);
          }}
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fa-solid fa-pen-to-square"
          />
        </Button>
        <Button
          content="Cambiar estado de usuario"
          className="w-[50px]"
          onClick={() => {
            requestAuth("put", `users/Activate/${userInfo?.userToReturn?.id}`)
              .then(() => {
                call?.();
                reload();
                toast.success("Se cambio el estado del usuario");
              })
              .catch(() => {
                toast.error("Se produjo un error al realizar los cambios");
              });
          }}
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fa-sharp fa-solid fa-eye-slash"
          />
        </Button>
        <Button
          content="Adjuntar documento"
          className="w-[50px]"
          onClick={() => {
            setactiveFormFile(true);
          }}
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fa-solid fa-upload"
          />
        </Button>
      </div>
      <ModalFullScreen
        setactivateForm={setDeleteFile}
        activateForm={deleteFile}
      >
        <Texto className="font-bold my-[10px]">
          ¡Se eliminara el archivo {dataFile?.name}!
        </Texto>
        <Button
          className="bg-red-500 text-white flex items-center justify-center my-[20px]"
          onClick={() => {
            requestAuth("delete", `/files/${dataFile.fileId}`)
              .then(() => {
                toast.success("Archivo eliminado");
                setDeleteFile(false);
                call();
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : "Fallo al eliminar el archivo"
                );
              });
          }}
        >
          Eliminar
        </Button>
      </ModalFullScreen>
      <SubModal
        setactivateForm={setactiveFormFile}
        activateForm={activeFormFile}
      >
        <H1>Adjuntar documento</H1>
        <FormDefault
          fields={[
            {
              label: "Nombre del archivo",
              type: "text",
              name: "Name",
              typeInput: "input",
            },
            {
              label: "Adjuntar un archivo",
              name: "File",
              type: "file",
              typeInput: "file",
            },
          ]}
          initialValues={{
            Name: "",
            File: "",
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required("Este campo es obligatorio"),
            File: Yup.mixed().required("Campo requerido.").nullable(),
          })}
          onSubmit={onSubmit}
          buttonName="Enviar"
        />
      </SubModal>
      <SubModal
        setactivateForm={setactivateFormEdit}
        activateForm={activateFormEdit}
      >
        <FormDefault
          tittle="Editar datos del usuario"
          fields={[
            {
              label: "Nombre",
              name: "Name",
              placeholder: "Introduzca su nombre",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Apellido paterno",
              name: "FatherLastName",
              placeholder: "Introduzca su apellido paterno",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Apellido materno",
              name: "MotherLastName",
              placeholder: "Introduzca su apellido materno",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Nombre de usuario",
              name: "Username",
              placeholder: "Introduzca su nombre de usuario",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Numero de celular",
              name: "CellphoneNumber",
              placeholder: "Introduzca su numero de celular",
              type: "number",
              typeInput: "input",
            },
            {
              label: "Carnet de identidad",
              name: "Ci",
              placeholder: "Introduzca su numero de carnet de identidad",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Ciudad de residencia",
              name: "City",
              type: "select",
              typeInput: "select",
              options: [
                {
                  label: "Santa cruz",
                  value: "Santa cruz",
                },
                {
                  label: "La paz",
                  value: "La paz",
                },
                {
                  label: "Cochabamba",
                  value: "Cochabamba",
                },
                {
                  label: "Sucre",
                  value: "Sucre",
                },
                {
                  label: "Oruro",
                  value: "Oruro",
                },
                {
                  label: "Tarija",
                  value: "Tarija",
                },
                {
                  label: "Beni",
                  value: "Beni",
                },
                {
                  label: "Pando",
                  value: "Pando",
                },
                {
                  label: "Potosi",
                  value: "Potosi",
                },
              ],
            },
            {
              label: "Email",
              name: "Email",
              placeholder: "correo@gmail.com",
              type: "email",
              typeInput: "input",
            },
            {
              label: "Contraseña",
              name: "Password",
              placeholder: "Introduzca su contraseña",
              type: "password",
              typeInput: "input",
            },
            {
              label: "Numero de telefono",
              name: "PhoneNumber",
              placeholder: "Introduzca su numero de telefono",
              type: "number",
              typeInput: "input",
            },
            {
              label: "Seleccione los roles",
              name: "Rol",
              placeholder: "Introduzca su nombre de usuario",
              type: "checkbox",
              typeInput: "checkboxes",
              urlApi: "/rol",
              value: "id",
            },
          ]}
          initialValues={{
            CellphoneNumber: userInfo?.userToReturn?.cellphoneNumber || "",
            Ci: userInfo?.userToReturn?.ci || "",
            City: userInfo?.userToReturn?.city || "Santa cruz",
            Email: userInfo?.userToReturn?.email || "",
            FatherLastName: userInfo?.userToReturn?.fatherLastName || "",
            MotherLastName: userInfo?.userToReturn?.motherLastName || "",
            Name: userInfo?.userToReturn?.name || "",
            PhoneNumber: userInfo?.userToReturn?.phoneNumber || "",
            Status: userInfo?.userToReturn?.status || "",
            Username: userInfo?.userToReturn?.username || "",
            Rol: userInfo?.rolsUserToReturn.map((rol) => "" + rol.id) || [],
          }}
          onSubmit={async (values, resetForm) => {
            await requestAuth(
              "put",
              `users/${userInfo.userToReturn.id}/UpdateUser`,
              {
                ...values,
                Rol: values.Rol.map((item) => ({
                  Id: item,
                })),
              }
            )
              .then(() => {
                call();
                reload();
                setactivateFormEdit(false);
                toast.success("Envio exitoso");
                getuser();
                resetForm();
              })
              .catch(() => {
                toast.error("Se produjo un error");
              });
          }}
          buttonName="Enviar"
        />
      </SubModal>
    </>
  );
};
export default UserInfo;
const exitEsc = (setClose) => {
  document.addEventListener("keydown", (event) => {
    if (event.which === 27 && event.keyCode === 27) {
      setClose(false);
    }
  });
};
