import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState } from "react";
import { H1, H3 } from "../../components/Text";
import Button from "../../components/Buttons/Button";
import SubModal from "../../components/Modal/SubModal";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const LendingList = () => {
  const [reload, setReload] = useState(false);
  const [modal, setModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [procedure, setProcedure] = useState(null);

  function handleReload() {
    setReload(!reload);
    setModal(!modal);
  }
  function handleReturn(item) {
    setProcedure(item);
    setReturnModal(!returnModal);
  }

  function handleBuilder(data, head) {
    if (data) {
      return "Entregado";
    }
    return <div className="text-red-500">Prestado</div>;
  }
  return (
    <div>
      <div className="flex justify-end">
        <Button onClick={() => setModal(!modal)}>PRESTAR CARPETA</Button>
      </div>
      <CallServiceTable
        urlApi="/loans"
        header={[
          {
            name: "number",
            label: "Tramite",
            filter: true,
          },
          {
            name: "nameCompany",
            label: "Cliente",
            filter: true,
          },
          {
            name: "reason",
            label: "Motivo",
            filter: false,
          },
          {
            name: "fullNameRequestingUser",
            label: "Solicitante",
            filter: false,
          },
          {
            name: "loanDate",
            label: "Fecha de préstamo",
            type: "date",
            filter: false,
          },
          {
            name: "tentativeDate",
            label: "Fecha tentativa de la devolución",
            type: "date",
            filter: false,
          },
          {
            name: "returnObservation",
            label: "Estado",
            type: "custom",
            builder: handleBuilder,
            filter: false,
          },
          {
            name: "returnDate",
            label: "Fecha de devolución",
            type: "date",
          },
          // {
          //   name: 'nameCompany',
          //   label: 'Compañía',
          //   filter: false,
          // },
          // {
          //   name: 'returnObservation',
          //   label: 'Estado',
          //   type: 'custom',
          //   builder: handleBuilder,
          //   filter: false,
          // },
        ]}
        addUrl={`type=giver&userId=${window.localStorage.getItem("id")}`}
        statusPaination={true}
        reload={reload}
        onClick={handleReturn}
        // onClick={() => setReturnModal(!returnModal)}
      />
      <CreateLoan
        activateForm={modal}
        setactivateForm={setModal}
        handleReload={handleReload}
      />
      {procedure && (
        <ReturnLoan
          activateForm={returnModal}
          setactivateForm={setReturnModal}
          handleReload={handleReturn}
          reload={() => setReload(!reload)}
          procedure={procedure}
        />
      )}
    </div>
  );
};

const ReturnLoan = (props) => {
  const { activateForm, setactivateForm, procedure, handleReload, reload } =
    props;

  const TextCustom = ({ title, data }) => (
    <H3 className="text-start">
      {title}: <span className="font-medium">{data}</span>
    </H3>
  );

  return (
    <SubModal setactivateForm={setactivateForm} activateForm={activateForm}>
      {procedure.returnObservation ? (
        <>
          <H1>Detalles del préstamo</H1>
          <TextCustom title="Nro. de trámite" data={procedure.nameProcedure} />
          <TextCustom
            title="Solicitante"
            data={procedure.fullNameRequestingUser}
          />
          <H3 className="text-start">
            Fecha de devolución:{" "}
            <span className="font-medium">
              {dayjs(procedure.returnDate).format("DD/MM/YYYY HH:mm")}
            </span>
          </H3>
          <br />
          <H3 className="text-start">OBSERVACIONES</H3>
          {procedure.observation && (
            <H3 className="text-start">
              Observaciones:{" "}
              <span className="font-medium">{procedure.observation}</span>
            </H3>
          )}
          {procedure.returnObservation && (
            <H3 className="text-start">
              Observaciones de la devolución:{" "}
              <span className="font-medium">{procedure.returnObservation}</span>
            </H3>
          )}
        </>
      ) : (
        <>
          <H1>
            Formulario de devolución del trámite ({procedure.nameProcedure})
          </H1>
          <FormDefault
            fields={[
              {
                label: "Observaciones de la devolución",
                name: "ReturnObservation",
                placeholder: "Observaciones de la devolución",
                type: "text",
                typeInput: "input",
              },
            ]}
            initialValues={{
              ReturnObservation: "",
            }}
            validationSchema={Yup.object().shape({
              ReturnObservation: Yup.string().required(
                "Este campo es obligatorio"
              ),
            })}
            onSubmit={(values) => {
              // api/loans/procedures/95
              // {"Reason":"aasdasd","RequestingUserId":"85","TentativeDate":"2022-08-25","Observation":"asdasdasd"}
              requestAuth("put", `/loans/${procedure.id}`, values)
                .then(() => {
                  handleReload();
                  reload();
                  toast.success("Préstamo agregado correctamente!!!");
                })
                .catch(() => {
                  toast.error("Se ha producido un error");
                });
            }}
            buttonName="Enviar"
          />
        </>
      )}
    </SubModal>
  );
};

const CreateLoan = (props) => {
  const { handleReload, activateForm, setactivateForm } = props;
  const [subModal, setSubModal] = useState(false);
  const [procedure, setProcedure] = useState(null);

  function handleProcedure(item) {
    setProcedure(item);
    setSubModal(!subModal);
  }

  return (
    <SubModal setactivateForm={setactivateForm} activateForm={activateForm}>
      <H1>Lista de tramites</H1>
      <CallServiceTable
        // urlApi="/procedure/openprocedures/1"
        urlApi="/loans/procedures"
        header={[
          {
            name: "number",
            label: "Tramite",
            filter: true,
          },

          {
            name: "companyName",
            label: "Compañía",
            filter: false,
          },
          {
            name: "currentStep",
            label: "Paso actual",
            filter: false,
          },
        ]}
        onClick={handleProcedure}
        statusPaination={true}
      />
      {procedure && (
        <FormAddLoan
          activateForm={subModal}
          setactivateForm={setSubModal}
          procedure={procedure}
          handleReload={handleReload}
        />
      )}
    </SubModal>
  );
};

const FormAddLoan = (props) => {
  const { activateForm, setactivateForm, procedure, handleReload } = props;
  return (
    <SubModal setactivateForm={setactivateForm} activateForm={activateForm}>
      <H1>Prestar el trámite ({procedure.number})</H1>
      <FormDefault
        fields={[
          {
            label: "Motivo del préstamo",
            name: "Reason",
            placeholder: "Introduzca el motivo del préstamo",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Seleccione el usuario solicitante",
            name: "RequestingUserId",
            typeInput: "select",
            urlApi: "/rol/PrestamoDeCarpetas",
            value: "id",
            labelOption: "name",
          },
          {
            label: "fecha tentativa de devolución",
            name: "TentativeDate",
            placeholder: "fecha tentativa de devolución",
            type: "date",
            typeInput: "input",
          },

          {
            label: "Observaciones",
            name: "Observation",
            placeholder: "Introduzca las observaciones",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          Reason: "",
          RequestingUserId: "",
          TentativeDate: "",
          Observation: "",
        }}
        validationSchema={Yup.object().shape({
          Reason: Yup.string().required("Este campo es obligatorio"),
          RequestingUserId: Yup.string().required("Este campo es obligatorio"),
          TentativeDate: Yup.string().required("Este campo es obligatorio"),
          Observation: null,
        })}
        onSubmit={(values) => {
          // api/loans/procedures/95
          // {"Reason":"aasdasd","RequestingUserId":"85","TentativeDate":"2022-08-25","Observation":"asdasdasd"}
          requestAuth("post", `/loans/procedures/${procedure.id}`, values)
            .then(() => {
              handleReload();
              toast.success("Préstamo agregado correctamente!!!");
            })
            .catch(() => {
              toast.error("Se ha producido un error");
            });
        }}
        buttonName="Enviar"
      />
    </SubModal>
  );
};

export default LendingList;
