import { useEffect } from "react";
import { H3, Label, Anchor } from "../../components/Text";
import Circle from "../../components/Animation/Circle";
import TableComplete from "../../components/Tables/TableComplete";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import FormDefault from "../../components/Forms/FormDefault";
import { Request } from "../../hooks/Request";
import { useDialog } from "../../contexts/DialogContext";
import * as Yup from "yup";

const FileStatus = ({ field, guide }) => {
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/fileState/procedure/${field.id}/field/${field.fieldId}`,
  });

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [field]);

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el archivo?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/fileState/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }
  function handleForm(data) {
    openDialog(
      <FormAdd
        data={data}
        onClose={dialogClose}
        reload={call}
        field={field}
        guide={guide}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <>
      <Label
        htmlFor={field.name}
        className={`${field.className} my-[0] mt-[25px]`}
      >
        {field.label}
      </Label>
      <div className="flex justify-between items-center">
        <H3 className="font-medium my-[10px] text-lg text-start">
          Lista de archivos subidos
        </H3>
        <Button
          type="button"
          className="px-2 py-1"
          onClick={() => handleForm({})}
        >
          <i className="fas fa-plus p-0 m-0"></i>
        </Button>
      </div>
      <div className="w-full">
        <TableComplete
          header={[
            {
              name: "number",
              label: "Nro",
            },
            {
              name: "url",
              label: "Enlace",
              type: "custom",
              builder: (item) => {
                if (item) {
                  return (
                    <Anchor
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </Anchor>
                  );
                } else {
                  return "-";
                }
              },
            },
            {
              name: "presentation",
              label: "Presentación",
            },
            {
              name: "status",
              label: "Estado",
            },
            {
              label: "Emisor",
              name: "provider",
            },
            {
              label: "Fecha",
              name: "creationDate",
              type: "date",
            },
            {
              name: "add",
              type: "action",
              label: "Opciones",
              sticky: true,
              actions: [
                {
                  label: "Añadir",
                  icon: "fas  fa-edit",
                  action: handleForm,
                  color: "text-[#1d4ed8]",
                },
                {
                  label: "Eliminar",
                  icon: "fas fa-trash",
                  action: handleDelete,
                  color: "text-red-500",
                },
              ],
            },
          ]}
          data={data}
        />
      </div>
    </>
  );
};

const FormAdd = ({ data, onClose, reload, field, guide }) => {
  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", field.label);
    formData.append("File", values.url);
    formData.append("originalName", values.url.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", field.id);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({ ...values, url: res.data.url, name: field.label });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    await requestAuth(
      data.id ? "put" : "post",
      data.id
        ? `/fileState/${data.id}`
        : `/fileState/procedure/${field.id}/field/${field.fieldId}`,
      { ...values, AirGuideId: guide.id }
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  return (
    <>
      <FormDefault
        tittle={field.label}
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
          {
            label: "Número",
            name: "number",
            placeholder: "Número",
            type: "text",
            typeInput: "input",
          },
          // {
          //   label: 'Presentar',
          //   name: 'presentation',
          //   type: 'select',
          //   typeInput: 'select',
          //   options: [
          //     {
          //       label: 'Digital',
          //       value: 'Digital',
          //     },
          //     {
          //       label: 'Físico',
          //       value: 'Físico',
          //     },
          //   ],
          // },
          // {
          //   label: 'Estado',
          //   name: 'status',
          //   type: 'select',
          //   typeInput: 'select',
          //   options: [
          //     {
          //       label: 'Original',
          //       value: 'Original',
          //     },
          //     {
          //       label: 'Copia',
          //       value: 'Copia',
          //     },
          //     {
          //       label: 'Fotocopia simple',
          //       value: 'Fotocopia simple',
          //     },
          //     {
          //       label: 'Fotocopia legalizada',
          //       value: 'Fotocopia legalizada',
          //     },
          //   ],
          // },
          // {
          //   label: 'Emisor',
          //   name: 'provider',
          // },
        ]}
        initialValues={{
          url: data.url ?? null,
          number: data.number ?? "",
          presentation: data.presentation ?? "Físico",
          status: data.status ?? "Original",
          provider: data.provider ?? "",
        }}
        validationSchema={Yup.object().shape({
          url: Yup.mixed().required("Campo requerido.").nullable(),
          number: Yup.string().required("Campo requerido"),
          status: Yup.string().required("Campo requerido"),
          provider: Yup.string(),
        })}
        onSubmit={(values) =>
          typeof values.url === "string" ? onSubmit(values) : uploadFile(values)
        }
        buttonName="Registrar"
      />
      <br />
    </>
  );
};

export default FileStatus;
