import View from './View'
import Text from './Text'
import { validateArray, validateUrlToOpen } from '../../utils/validate'
import * as dayjs from 'dayjs'
import { Link } from '@react-pdf/renderer'
// import { useAuth } from '../../contexts/AuthContext'

const CustomTablePDF = (props) => {
  const { user, fields = [], data = [], border } = props
  // const { user } = useAuth()
  console.log(user)

  function validateType(value, head) {
    const listData = {
      date: dayjs(value).format('DD/MM/YYYY HH:mm'),
      custom: head.builder ? head.builder(value, head) : null,
      translate: head.translate ? head.translate[value] : '',
    }
    return listData[head.type] || value
  }
  if (!validateArray(fields)) {
    return null
  }
  return (
    <View flexDirection="column">
      <View border={border} width="100%">
        {fields.map((field, index) => (
          <View
            key={index}
            border={{ right: index + 1 < fields.length ? true : false }}
            width={field.width ? `${field.width}%` : 'auto'}
          >
            <Text bold style={{ margin: '0 auto' }}>
              {field.label}
            </Text>
          </View>
        ))}
      </View>
      {validateArray(data)
        ? data.map((item, idx) => (
          <View
            key={idx}
            border={{ right: true, bottom: true, left: true }}
            width="100%"
          >
            {fields.map((field, idy) => (
              <View
                key={idy}
                border={{ right: idy + 1 < fields.length ? true : false }}
                width={field.width ? `${field.width}%` : 'auto'}
              >
                {
                  field.type !== 'link'
                    ?
                    (
                      <Text>{field.name === "userInChange" ? (item[field.name] != null ? item[field.name].replace(',', '\n') : validateType(item[field.name], field)) : validateType(item[field.name], field)}</Text>
                    )
                    :
                    item[field.name] && item[field.name] !== 'null'
                      ?
                      (
                        
                        <Link src={`${validateUrlToOpen(item[field.name], user?.institutionParam)}`}>
                        {/* <Link src={item[field.name]}> */}
                          <Text color="#1d4ed8">Documento</Text>
                        </Link>
                      )
                      :
                      (
                        <Text color="#1d4ed8">--</Text>
                      )
                }
              </View>
            ))}
          </View>
        ))
        : null}
    </View>
  )
}

export default CustomTablePDF
