import { useEffect, useState, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestAuth } from '../components/services/RequestService'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()

  const navigate = useNavigate()
  async function getuser() {
    await requestAuth('get', 'users/User')
      .then((response) => {
        setUser({
          user: response.data.userToReturn,
          rols: response.data.rolsUserToReturn,
          company: response.data.companys,
          institutionParam: response.data.institutionParam
        })
      })
      .catch(() => {
        localStorage.removeItem('token_seguridad')
        localStorage.removeItem('id')
        navigate('/')
      })
  }
  useEffect(() => {
    const token = localStorage.getItem('token_seguridad')
    if (token) {
      getuser()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, setUser, getuser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
