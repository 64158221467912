import { useAuth } from "../../contexts/AuthContext";
import { validateUrlToOpen } from "../../utils/validate";
const Anchor = (props) => {
  const { children, href, ...rest } = props;
  const { user } = useAuth();
  return (
    <a
      href={validateUrlToOpen(href, user?.institutionParam)}
      // href={href[0] === "/" ? `${window.location.origin}${href}` : href}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Anchor;
