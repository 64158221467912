import { H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { useModal } from '../../contexts/ModalContext'
import { useDialog } from '../../contexts/DialogContext'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import TableComplete from '../../components/Tables/TableComplete'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Request } from '../../hooks/Request'
import { Documents } from '../../containers/AirWaybills'

const AirWaybills = () => {
  const { openModal, onClose } = useModal()

  function handleNewGuide(tableReload) {
    openModal(<FormAddGuide reload={tableReload} onClose={onClose} />)
  }

  function handleDetailGuide(guide, tableReload) {
    openModal(
      <GuideInfo
        guide={guide}
        reload={tableReload}
        openModal={openModal}
        onClose={onClose}
      />,
    )
  }

  return (
    <div>
      <CallServiceTable
        onClick={handleDetailGuide}
        urlApi="/AirGuide"
        component={({ reload }) => {
          return (
            <Button
              className="text-lg py-2"
              onClick={() => handleNewGuide(reload)}
            >
              Crear guía <i className="fa-solid fa-plus"></i>
            </Button>
          )
        }}
        header={[
          {
            label: 'N° de guía',
            name: 'numberGuide',
            filter: true,
          },
          {
            label: 'Proveedor',
            name: 'companyName',
          },
          {
            label: 'Courrier',
            name: 'courrier',
            filter: true,
          },
          {
            name: 'url',
            label: 'Enlace',
            type: 'custom',
            builder: (item) => {
              if (item) {
                return (
                  <a
                    href={item}
                    className="text-[#1d4ed8] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir
                  </a>
                )
              } else {
                return '-'
              }
            },
          },
          //   {
          //     name: 'edit',
          //     type: 'action',
          //     label: 'Enviar credenciales',
          //     actions: [
          //       {
          //         label: 'Reenviar credenciales',
          //         icon: 'fas fa-mail-bulk',
          //         action: sendEmail,
          //         color: 'text-[#1d4ed8]',
          //       },
          //     ],
          //   },
        ]}
      />
    </div>
  )
}

const FormAddGuide = (props) => {
  const { onClose, reload } = props

  async function onHandleFile(values) {
    let formData = new FormData()
    formData.append('Name', values.numberGuide)
    formData.append('File', values.url)
    formData.append('Status', 'verified')
    await requestAuth('post', '/files', formData)
      .then((res) => {
        onSubmit({ ...values, url: res.data.url })
      })
      .catch(() => {
        toast.error('Se produjo un error al subir el archivo')
      })
  }
  async function onSubmit(values) {
    await requestAuth('post', '/AirGuide', values)
      .then(() => {
        onClose()
        reload()
        toast.success('Registro exitoso')
      })
      .catch((response) => {
        toast.error(
          response.response.data
            ? response.response.data
            : 'Se produjo un error al enviar',
        )
      })
  }

  return (
    <div>
      <FormDefault
        tittle="Crear guía aérea"
        fields={[
          // {
          //   label: 'Proveedor',
          //   name: 'provider',
          // placeholder: 'Introduzca el proveedor',
          // },
          {
            label: 'Proveedor',
            name: 'companyId',
            typeInput: 'select',
            urlApi: '/company/getallcompanies',
            value: 'id',
            labelOption: 'razonSocial',
          },
          {
            label: 'Courrier',
            name: 'courrier',
            placeholder: 'Introduzca el courrier',
          },
          {
            label: 'N° de guía',
            name: 'numberGuide',
            placeholder: 'Introduzca el nro de guía',
          },
          {
            label: 'Archivo',
            name: 'url',
            type: 'file',
            typeInput: 'file',
          },
        ]}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          companyId: Yup.string().required('Este campo es obligatorio'),
          courrier: Yup.string().required('Este campo es obligatorio'),
          numberGuide: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={onHandleFile}
        buttonName="Crear guía"
      />
    </div>
  )
}

const GuideInfo = ({ guide, openModal }) => {
  const { openDialog, dialogClose } = useDialog()
  const { data, loading, call } = Request({
    urlApi: `/AirGuide/${guide.id}/fileStatus`,
  })
  function handleAddFile() {
    openDialog(
      <ListProcedure
        guide={guide}
        openDialog={openDialog}
        onClose={dialogClose}
        openModal={openModal}
      />,
    )
  }

  if (!loading) {
    return null
  }
  return (
    <div>
      <div className="flex justify-between items-center">
        <H3 className="font-medium my-[10px] text-lg text-start">
          Lista de archivos subidos
        </H3>
        <Button className="text-lg py-2" onClick={() => handleAddFile()}>
          Subir documento <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <TableComplete
        header={[
          {
            name: 'procedureNumber',
            label: 'Tramite',
          },
          {
            name: 'internNumber',
            label: 'Nro. interno',
          },
          // {
          //   name: 'dimOdex',
          //   label: 'Nro. DIM/DEX',
          // },
          {
            name: 'name',
            label: 'documento',
          },
          {
            name: 'number',
            label: 'Nro',
          },
          {
            name: 'url',
            label: 'Enlace',
            type: 'custom',
            builder: (item) => {
              if (item) {
                return (
                  <a
                    href={item}
                    className="text-[#1d4ed8] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir
                  </a>
                )
              } else {
                return '-'
              }
            },
          },
          // {
          //   name: 'presentation',
          //   label: 'Presentación',
          // },
          // {
          //   name: 'status',
          //   label: 'Estado',
          // },
          // {
          //   label: 'Emisor',
          //   name: 'provider',
          // },
          {
            label: 'Fecha',
            name: 'creationDate',
            type: 'date',
          },
          // {
          //   name: 'add',
          //   type: 'action',
          //   label: 'Opciones',
          //   sticky: true,
          //   actions: [
          //     {
          //       label: 'Añadir',
          //       icon: 'fas  fa-edit',
          //       action: handleForm,
          //       color: 'text-[#1d4ed8]',
          //     },
          //     {
          //       label: 'Eliminar',
          //       icon: 'fas fa-trash',
          //       action: handleDelete,
          //       color: 'text-red-500',
          //     },
          //   ],
          // },
        ]}
        data={data}
      />
    </div>
  )
}

const ListProcedure = ({ guide, onClose, openModal }) => {
  function handleSelect(value) {
    onClose()
    openModal(
      <AddFileStatus procedure={value} guide={guide} openModal={openModal} />,
    )
  }
  return (
    <CallServiceTable
      onClick={handleSelect}
      filters={[
        {
          name: 'companyId',
          url: '/company/getallcompanies',
          default: 'Todas las compañías',
          valueOption: 'id',
          labelOption: 'razonSocial',
        },
      ]}
      urlApi="/procedure/openprocedures"
      addFilters={[
        {
          name: 'procedureNumber',
          label: 'Tramite',
          filter: true,
        },
        {
          name: 'internCode',
          label: 'Nro. interno',
          filter: true,
        },
        {
          name: 'dimNumber',
          label: 'Nro. DIM/DEX',
          filter: true,
        },
      ]}
      header={[
        {
          name: 'number',
          label: 'Trámite',
        },
        {
          name: 'internCode',
          label: 'Nro. interno',
        },
        {
          name: 'dimNumber',
          label: 'Nro. DIM/DEX',
        },
        {
          name: 'companyName',
          label: 'Cliente',
        },
        {
          name: 'currentStepName',
          label: 'Paso Actual',
          type: 'custom',
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return 'Terminado'
            }
            return value
          },
        },
      ]}
    />
  )
}

const AddFileStatus = (props) => {
  const { procedure, guide, openModal } = props

  function handleBack() {
    openModal(<GuideInfo guide={guide} openModal={openModal} />)
  }
  return (
    <div>
      <div className="flex items-center gap-2">
        <Button onClick={handleBack} className="py-1">
          <i className="fas fa-angle-left"></i>
        </Button>
        <H3 className="font-medium my-[10px] text-xl text-start mx-auto">
          Subir documentos
        </H3>
      </div>
      <Documents procedure={procedure} guide={guide} />
    </div>
  )
}

export default AirWaybills
