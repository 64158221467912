import { useEffect, useState } from "react";
import { Request } from "../../hooks/Request";
import { H1, H3, Anchor } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { App } from "../../containers/VerticalChart";
import SubModal from "../../components/Modal/SubModal";
import Loading from "../../components/Animation/Loading";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import { validateArray } from "../../utils/validate";
import { TableManagement } from "../../containers/Systems/TableManagement";

const Management = () => {
  const [userStat, setuserStat] = useState();
  const [count, setcount] = useState(0);
  const [Info, setInfo] = useState();
  const [activeModalTramit, setactiveModalTramit] = useState(false);
  const [defaultProcedureType, setdefaultProcedureType] = useState(1);
  const [filterLiq, setfilterLiq] = useState("pending");

  const { openDialog, dialogClose } = useDialog();

  // const { data, call: callCardData } = Request({
  //   // urlApi: 'procedureStats/for-cards?procedureTypeId=1&internCode=2022',
  //   urlApi: `procedureStats/for-cards?procedureTypeId=${defaultProcedureType}`,
  // })
  // const { data: completeData } = Request({
  //   // urlApi: '/procedureStats/closed-per-step?internCode=2022',
  //   urlApi: '/procedureStats/closed-per-step',
  // })
  const { data: proceduresData, call: callProcedureData } = Request({
    // urlApi: 'procedureStats/in-process?internCode=2022',
    urlApi: `procedureStats/in-process?procedureTypeId=${defaultProcedureType}`,
  });

  const { data: perCompany, call: callPerCompany } = Request({
    // urlApi: 'procedureStats/perCompany?internCode=2022',
    urlApi: `procedureStats/perCompany?procedureTypeId=${defaultProcedureType}`,
  });

  const { data: perLiquidator, call: callPerLiquidator } = Request({
    // urlApi: 'procedureStats/perCompany?internCode=2022',
    urlApi: `procedureStats/perLiquidator?procedureTypeId=${defaultProcedureType}&status=${filterLiq}`,
  });

  // function infoTramitProces(element) {
  //   setInfo(element)
  //   setactiveModalTramit(true)
  // }

  function generateItems() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de los tramites?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/files/generalFormat"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }
  function generateArchive() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de archivo?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/gamarra/archiveData"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  const { data: procedureTypes } = Request({
    urlApi: `procedureType`,
  });

  useEffect(() => {
    if (count > 0) {
      callProcedureData();
      callPerCompany();
      callPerLiquidator();
    }
    setcount(count + 1);
  }, [defaultProcedureType]);

  useEffect(() => {
    if (count > 0) {
      callPerLiquidator();
    }
    setcount(count + 1);
  }, [filterLiq]);

  return (
    <div className="">
      <div className="flex justify-end gap-2">
        <Button
          position="left"
          content="Generar reporte"
          onClick={() => generateItems()}
        >
          <i className="fas fa-book"></i>
          {/* <i className="fas fa-file-excel"></i> */}
        </Button>
        <Button
          position="left"
          content="Generar reporte de archivo"
          onClick={() => generateArchive()}
        >
          <i className="fas fa-archive"></i>
        </Button>
      </div>
      <H1>GERENCIA</H1>
      <H3>
        {validateArray(procedureTypes) ? null : null}
        <select
          name="SearchSelect"
          className="bg-[#ff8640] border border-gray-100 text-[#ffffff] text-md font-semibold rounded-lg px-2.5 py-2"
          onChange={(e) => {
            setdefaultProcedureType(e.target.value);
          }}
        >
          {procedureTypes?.map((value, key) => (
            <option key={key} value={value.id}>
              {value?.name?.toUpperCase()}
            </option>
          ))}
        </select>
      </H3>
      <div className="py-[30px] grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <H3 className="pb-2"> CANTIDAD DE TRAMITES POR COMPAÑIA</H3>
          {perCompany !== undefined ? (
            <>
              <TableManagement
                header={[
                  {
                    label: "Compañia",
                    name: "razonSocial",
                    aditionalClass: "bg-[#ffeadd]",
                  },
                  {
                    label: "Cantidad",
                    name: "amount",
                    aditionalClass: "font-bold text-gray-500",
                  },
                ]}
                data={perCompany}
                labelGrafic="trámite"
              />
            </>
          ) : (
            <Loading />
          )}
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <H3 className=""> CANTIDAD DE TRAMITES POR LIQUIDADOR</H3>
            <select
              name="SearchSelect"
              className="bg-[#ff8640] border border-gray-100 text-[#ffffff] text-md font-semibold rounded-lg px-2.5 py-2"
              onChange={(e) => {
                setfilterLiq(e.target.value);
              }}
              value={filterLiq}
            >
              <option value="">Todos los tramites</option>
              <option value="pending">Tramites pendientes</option>
              <option value="finished">Tramites concluidos</option>
            </select>
          </div>
          {perCompany !== undefined ? (
            <>
              <TableManagement
                header={[
                  {
                    label: "Liquidador",
                    name: "name",
                    aditionalClass: "bg-[#ffeadd]",
                  },
                  {
                    label: "Cantidad",
                    name: "amount",
                    aditionalClass: "font-bold text-gray-500",
                  },
                ]}
                data={perLiquidator}
                labelGrafic="trámite"
                TextEmptyList="No hay tramites"
              />
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <div className="w-[70%] mx-auto py-[30px]">
        <H3>CANTIDAD DE TRÁMITES POR PASOS</H3>
        {proceduresData !== undefined ? (
          <>
            <App
              labelGrafic="trámite"
              label="stepName"
              value="amount"
              backgroundColorRGB="255, 93, 0, 0.6"
              tramit={proceduresData}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
      <SubModal
        setactivateForm={setactiveModalTramit}
        activateForm={activeModalTramit}
      >
        <div className="bg-white p-[30px]">
          <H3>ESTADISTICAS DE USUARIO</H3>
          <H3>{Info}</H3>
          {userStat !== undefined ? (
            <>
              <App
                labelGrafic="trámite"
                onClick={setactiveModalTramit}
                label="name"
                value="amount"
                backgroundColorRGB="255, 2, 101, 0.6 "
                tramit={userStat.userStat}
              />
            </>
          ) : (
            <Loading />
          )}
        </div>
      </SubModal>
    </div>
  );
};

export default Management;
