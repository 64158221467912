import * as Yup from "yup";

export const validateType = {
  text: Yup.string().required("Campo requerido"),
  controlField: Yup.string().required("Campo requerido"),
  selectGet: Yup.string().required("Campo requerido"),
  file: Yup.mixed().required("Campo requerido.").nullable(),
  fileRead: Yup.mixed().required("Campo requerido.").nullable(),
  fileExcel: null,
  // fileExcel: Yup.string().required('Campo requerido'),
  number: Yup.number()
    .typeError("Eso no parece un número")
    .required("Campo requerido"),
};

export const headerListContent = [
  { name: "companyName", label: "Cliente" },
  { name: "prioridad", label: "Prioridad" },
  { name: "initialDate", label: "Fecha de inicio" },
  { name: "dimNumber", label: "Nro. DIM/DEX" },
  { name: "proveedor", label: "Proveedor" },
  { name: "facturaComercial", label: "Factura Comercial" },
  { name: "empresaDeTransporte", label: "Empresa de Transporte" },
  { name: "nroEmbarque", label: "Nro. de embarque" },
  { name: "stepDetail", label: "Paso actual" },
  { name: "aduana", label: "Aduana" },
  { name: "descripcionMercancia", label: "Mercadería" },
  {
    name: "regimenPatron",
    label: "Regimen/Patrón",
    type: "custom",
    builder: (value) => {
      return (
        <div
          className={`text-white ${
            value === "IMA4 DESPACHO ANTICIPADO IMP A CONSUMO" ||
            value === "IMI4 DESPACHO INMEDIATO IMP A CONSUMO"
              ? "text-red-500"
              : "text-gray-500"
          } text-center rounded-full py-1 px-2`}
        >
          {value}
        </div>
      );
    },
  },
  { name: "estadoRecojo", label: "Estado de recojo" },
  { name: "estadoArribo", label: "Estado de arribo" },
  { name: "estadoPago", label: "Estado de pago" },
  { name: "gestorAsignado", label: "Gestor" },
  { name: "liquidadorAsignado", label: "Liquidador" },
  { name: "encargadoTramite", label: "Encargado" },
  {
    name: "canal",
    label: "Canal",
    type: "custom",
    builder: (value) => {
      return (
        <div
          className={`text-white ${
            value === "Canal Rojo"
              ? "bg-[#fd3838A1]"
              : value === "Canal Amarillo"
              ? "bg-[#f3e40c51] text-[#747474]"
              : value === "Canal Verde"
              ? "bg-[#4fce4431] text-gray-500"
              : "text-gray-500"
          } text-center rounded-full py-1 px-2`}
        >
          {value}
        </div>
      );
    },
  },
  { name: "almacen", label: "Almacén" },
  { name: "nextStep", label: "Progreso" },
  { name: "montoAlmacenaje", label: "Monto de Almacenaje" },
  { name: "transportista", label: "Transportista" },
  { name: "fechaRetiroMercaderia", label: "Retiro de Mercadería" },
  { name: "fechaEntregaMercaderia", label: "Entrega de Mercadería" },
  { name: "fechaEnvioDocumentosCliente", label: "Envió de documentos cliente" },
];

export const headerList = [
  {
    name: "internCode",
    label: "Nro. interno",
    filter: true,
  },
  ...headerListContent,
];
export const filtersDefault = [
  {
    name: "status",
    default: "Todos los trámites",
    options: [
      {
        label: "Concluidos",
        name: "finished",
      },
      {
        label: "Pendientes",
        name: "pending",
      },
    ],
  },
  {
    name: "companyId",
    url: "/company/getallcompanies",
    default: "Todas las compañías",
    valueOption: "id",
    labelOption: "razonSocial",
  },
  {
    // url: '/company/getallcompanies',
    // name: 'companyId',
    // valueOption: 'id',
    // labelOption: 'razonSocial',
    type: "rangeDate",
  },
];

export const dataGeneral = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "fa-regular fa-rectangle-list",
    // type: 'table',
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
      {
        id: 5,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo5",
        type: "text",
      },
      {
        id: 6,
        label: "Example",
        initial: "45",
        name: "campo6",
        type: "text",
      },
      {
        id: 7,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo7",
        type: "text",
      },
      {
        id: 8,
        label: "Example4",
        initial: "4544",
        name: "campo8",
        type: "text",
      },
      {
        id: 9,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo9",
        type: "text",
      },
      {
        id: 10,
        label: "Example",
        initial: "45",
        name: "campo10",
        type: "text",
      },
      {
        id: 11,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo11",
        type: "text",
      },
      {
        id: 12,
        label: "Example4",
        initial: "4544",
        name: "campo12",
        type: "text",
      },
      {
        id: 13,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo13",
        type: "text",
      },
      {
        id: 14,
        label: "Example4",
        initial: "4544",
        name: "campo14",
        type: "text",
      },
    ],
  },
  {
    id: 2,
    head: "Operadores",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Aduana",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    head: "Lugares",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Destino",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 4,
    head: "Transporte",
    icon: "",
    type: "list",
    items: [
      {
        id: 1,
        label: "A3 Transporte",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
    ],
  },
];
export const dataGeneral2 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];
export const dataGeneral3 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];
