export const headerProviders = [
  {
    name: "number",
    label: "#",
    hidden: true,
  },
  {
    name: "typeDoc",
    label: "Tipo de documento",
    type: "select",
    typeInput: "select",
    options: [
      {
        label: "NIT - Número de identificación",
        value: "NIT",
      },
      {
        label: "CI - Cédula de identidad",
        value: "CI",
      },
      {
        label: "PAS - PASAPORTE",
        value: "PAS",
      },
      {
        label: "CIE - CÉDULA DE EXTRANJERÍA",
        value: "CIE",
      },
      {
        label: "DIE - DOCUMENTO DE IDENTIFICACIÓN",
        value: "DIE",
      },
    ],
  },
  {
    name: "NroDocumento",
    label: "Nro. de documento",
  },
  {
    name: "razonSocial",
    label: "Nombre/Razón social",
  },
  {
    name: "streetAvenue",
    label: "Calle/Avenida",
  },
  {
    name: "homeNumber",
    label: "N° de domicilio",
  },
  {
    name: "zone",
    label: "Barrio/Zona",
  },
  {
    name: "country",
    label: "País",
    type: "select",
    typeInput: "select",
    urlApi: "/Dropdown/paises/options",
    // reference: "Type",
    // condition: "selectGet,selectNewOption,subItemsConta,subData,subDataAdd",
    labelOption: "name",
    value: "name",
  },
  {
    name: "stateRegionProvince",
    label: "Estado/Departamento/Provincia",
    type: "subSelect",
    typeInput: "subSelect",
    fatherName: "country",
    fatherUrl: "/Dropdown/paises/options",
    urlApi: "departamentos",
    labelOption: "name",
    value: "name",
  },
  {
    name: "city",
    label: "Ciudad",
    // type: "subSelect",
    // typeInput: "subSelect",
    // fatherName: "country",
    // fatherUrl: "/Dropdown/paises/options",
    // urlApi: "departamentos",
    // labelOption: "name",
    // value: "name",
  },
  {
    name: "phone",
    label: "Teléfono/fax",
  },
  {
    name: "webSite",
    label: "Sitio web",
  },
  {
    name: "email",
    label: "Correo electrónico",
  },
];
export const headerBills = [
  {
    name: "number",
    label: "#",
    hidden: true,
  },
  {
    name: "e1Proveedor",
    label: "E1. Proveedor",
  },
  {
    name: "e1_1Condicion",
    label: "E.1.1. Condición",
  },
  {
    name: "e2PaisDeAdquisicion",
    label: "E2. País de adquisición",
  },
  {
    name: "e3NroDeFactura",
    label: "E3. N° de la factura",
  },
  {
    name: "e4FechaDeFactura",
    label: "E4. Fecha de la factura",
  },
  {
    name: "e5CondicionDeEntregaIncoterm",
    label: "E5. Condición de entrega (INCOTERM)",
  },
  {
    name: "e5_1Especifique",
    label: "E5.1. Especifique",
  },
  {
    name: "e6LugarDeEntregaIcoterm",
    label: "E6. Lugar de entrega (INCOTERM)",
  },
  {
    name: "e7NaturalezaDeTransaccion",
    label: "E7. Naturaleza de la transacción",
  },
  {
    name: "e7_1Especifique",
    label: "E7.1. Especifique",
  },
  {
    name: "e8MonedaDeTransaccion",
    label: "E8. Moneda de transacción",
  },
  {
    name: "e9ValorTotalDeTransaccion",
    label: "E9. Valor total de transacción",
  },
  {
    name: "e10TipoDeCambioMonedaDeTransaccion",
    label: "E10. Tipo de cambio de la moneda de transacción",
  },
  {
    name: "e11DestinoDeMercancia",
    label: "E11. Destino de la Mercancía",
  },
  { name: "e11_1Especifique", label: "E11.1 Esqecifique" },
  {
    name: "e12FacturaSujetaADescuento",
    label: "E12. Factura sujeta a descuento",
  },
  { name: "e13FormaDePago", label: "E13. Forma de pago" },
  { name: "e13_1Especifique", label: "E13.1 Esqecifique" },
  { name: "e14MedioDePago", label: "E14. Medio de pago" },
  { name: "e14_1Especifique", label: "E14.1 Esqecifique" },
  {
    name: "e15ValorFobTotalUsd",
    label: "E15. Valor FOB total (USD)",
  },
  { name: "e15_1Especifique", label: "Especifique " },
  { name: "e25NivelComercial", label: "E25. Nivel comercial" },
  { name: "e25_1Especifique", label: "E25.1 Especifique" },
];

export const headerItem = [
  {
    name: "itemNumber",
    label: "H1. N° Item",
    hidden: true,
  },
  { name: "nroDeFactura", label: "N° de la factura" },
  {
    name: "tratamientoEspecial",
    label: "H2. Tratamiento Especial",
  },
  {
    name: "subpartida",
    label: "H3. Subpartida arancelaria",
  },
  {
    name: "codigoComplementario",
    label: "H4. Código complementario",
  },
  {
    name: "codigoSuplementario",
    label: "H5. Código suplementario",
  },
  {
    name: "descripcionArancelaria",
    label: "Descripción arancelaria",
  },
  { name: "unidadDeMedida", label: "H6. Unidad de medida" },
  {
    name: "unidadFisicaConversion",
    label: "H6.1. Unidad física conversión",
  },
  {
    name: "cantidadUnidadFisica",
    label: "H7. Cantidad unidad física",
  },
  {
    name: "cantidadFisicaConversion",
    label: "H7.1. Cantidad física conversión",
  },
  { name: "unidadComercial", label: "H9. Unidad comercial" },
  {
    name: "cantidadComercial",
    label: "H10. Cantidad comercial",
  },
  {
    name: "precioUnitarioUsd",
    label: "H11. Precio unitario (USD)",
  },
  { name: "paisDeOrigen", label: "H12. País de origen" },
  {
    name: "ciudadEstadoRegion",
    label: "H13. Ciudad/Estado/Región",
  },
  { name: "acuerdoComercial", label: "H14. Acuerdo comercial" },
  { name: "criterioDeOrigen", label: "H15. Criterio de origen" },
  { name: "embalaje", label: "H16. Embalaje" },
  { name: "cantidadDeBultos", label: "H17. Cantidad de bultos" },
  { name: "pesoBrutoKg", label: "H18. Peso bruto (Kg)" },
  { name: "pesoNetoKg", label: "H19. Peso neto (Kg)" },
  { name: "marca", label: "H20. Marcas" },
  { name: "estado", label: "H21. Estado" },
  { name: "especifique", label: "H20.1. Especifique" },
  { name: "resolucionRitex", label: "H21. Resolución RITEX" },
  { name: "codigoRitex", label: "H22. Código RITEX" },
  { name: "cantidadRitex", label: "H23. Cantidad RITEX" },
  {
    name: "declaracionPrecedente",
    label: "H24. Declaración precedente",
  },
  {
    name: "modalidadDeRegimenPrecedente",
    label: "H25. Modalidad de régimen precedente",
  },
  { name: "itemPrecedente", label: "H26. Ítem precedente" },
  {
    name: "valorDeTransaccionDeItemUsd",
    label: "I1. Valor de transacción del Item (USD)",
  },
  {
    name: "valorFobDelItemUsd",
    label: "I2. Valor FOB del item (USD)",
  },
  {
    name: "obsDelItem",
    label: "J1. Observaciones del item",
  },
  {
    name: "especifiqueUComercial",
    label: "H9.1 Especifique U. Comercial",
  },
  {
    name: "relacionItemNroDeBultos",
    label: "H16. Relación Item-N° de bulto (s)",
  },
  {
    name: "descripcionUnidadTcf",
    label: "Descripción Unidad TCF",
  },
  {
    name: "cantidadTimbre",
    label: "Cantidad timbre",
  },
  {
    name: "cantidadRango",
    label: "Cantidad rango",
  },
  {
    name: "ley1391DS4579",
    label: "Ley1391 & DS4579",
  },
];

export const headerMinimalDescription = [
  {
    name: "itemNumber",
    label: "# item",
    hidden: true,
  },
  { name: "codigoFormulario", label: "Codigo Formulario" },
  { name: "codigoFormulario2", label: "Codigo Formulario2" },
  { name: "proveedorFactura", label: "Proveedor - Factura" },
  { name: "codigo1", label: "Codigo 1" },
  { name: "valor1", label: "Valor 1" },
  { name: "codig2", label: "Codigo 2" },
  { name: "valor2", label: "Valor 2" },
  { name: "codigo3", label: "Codigo 3" },
  { name: "valor3", label: "Valor 3" },
  { name: "codigo4", label: "Codigo 4" },
  { name: "valor4", label: "valor 4" },
  { name: "codigo5", label: "Codigo 5" },
  { name: "valor5", label: "Valor 5" },
  { name: "codigo6", label: "Codigo 6" },
  { name: "valor6", label: "Valor 6" },
  { name: "codigo7", label: "Codigo 7" },
  { name: "valor7", label: "Valor 7" },
  { name: "codigo8", label: "Codigo 8" },
  { name: "valor8", label: "Valor 8" },
  { name: "codigo9", label: "Codigo 9" },
  { name: "valor9", label: "Valor 9" },
  { name: "codigo10", label: "Codigo 10" },
  { name: "valor10", label: "Valor 10" },
  { name: "codigo11", label: "Codigo 11" },
  { name: "valor11", label: "Valor 11" },
  { name: "codigo12", label: "Codigo 12" },
  { name: "valor12", label: "Valor 12" },
  { name: "codigo13", label: "Codigo 13" },
  { name: "valor13", label: "Valor 13" },
  { name: "codigo14", label: "Codigo 14" },
  { name: "valor14", label: "Valor 14" },
  { name: "codigo15", label: "Codigo 15" },
  { name: "valor15", label: "Valor 15" },
];
