export const fileFormInput = [
  {
    label: "Nombre del campo",
    name: "Label",
    placeholder: "Nombre del campo",
    type: "text",
    typeInput: "input",
  },
  {
    label: "Tipo",
    name: "Type",
    type: "select",
    typeInput: "select",
    options: [
      {
        label: "Texto",
        value: "text",
      },
      {
        label: "Numero",
        value: "number",
      },
      { label: "Fecha", value: "date" },
      { label: "Fecha y hora", value: "datetime-local" },
      { label: "Lista desplegable", value: "selectGet" },
      { label: "Lista desplegable (adición)", value: "selectNewOption" },
      {
        label: "Archivo",
        value: "file",
      },
      {
        label: "Archivo con estado",
        value: "fileStatus",
      },
      {
        label: "Campo con valor multiple",
        value: "subData",
      },
      {
        label: "Campo con valor multiple (adición)",
        value: "subDataAdd",
      },
      {
        label: "Campo con valor multiple contabilidad (adición)",
        value: "subDataContaAdd",
      },
      {
        label: "Items de contabilidad",
        value: "subItemsConta",
      },
      {
        label: "Solicitar número correlativo",
        value: "requestSerialNumber",
      },
      {
        label: "Campo de control",
        value: "controlField",
      },
      {
        label: "Campo de control de entrega mercadería",
        value: "controlFieldDelivery",
      },
      {
        label: "Campo de incidencia",
        value: "IncidentField",
      },
      {
        label: "Archivo para la lectura",
        value: "fileRead",
      },
      { label: "Generar excel", value: "fileExcel" },
      { label: "Revisar excel", value: "reviewExcel" },
      { label: "Corregir excel", value: "correctExcel" },
      { label: "Casilla de verificación", value: "checkbox" },
      { label: "Caja de texto multi-línea", value: "textArea" },
      { label: "Completar documentos", value: "completeDocuments" },
      {
        label: "Completar documentos (Crear Opciones)",
        value: "completeDocumentsAdd",
      },
    ],
  },
  {
    label: "Tipo de archivo",
    name: "Url",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Tipo de archivo",
        value: "",
      },
      {
        label: "Parte de recepción",
        value: "parteDeRecepcion",
      },
      {
        label: "DIM",
        value: "adjuntarDim",
      },
      {
        label: "DIM Regularizada",
        value: "dimRegularizada",
      },
      {
        label: "DEX",
        value: "adjuntarDex",
      },
      {
        label: "DAM",
        value: "documentoDam",
      },
    ],
    reference: "Type",
    condition: "fileRead",
  },
  {
    label: "Tipo de documento",
    name: "Url",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Tipo de archivo",
        value: "",
      },
      {
        label: "DAV",
        value: "dav",
      },
      {
        label: "DAM",
        value: "dam",
      },
      {
        label: "DIM",
        value: "dim",
      },
      {
        label: "DEX",
        value: "dex",
      },
      {
        label: "Clonar Items",
        value: "clone-items",
      },
    ],
    reference: "Type",
    condition: "fileExcel,reviewExcel,correctExcel",
  },
  {
    label: "Seleccione la lista desplegable",
    name: "Url",
    type: "select",
    typeInput: "select",
    urlApi: "/Dropdown/List",
    reference: "Type",
    condition:
      "selectGet,selectNewOption,subItemsConta,subData,subDataAdd,subDataContaAdd",
    labelOption: "name",
    value: "urlLabel",
  },
  {
    label: "Color",
    name: "Color",
    placeholder: "Color",
    type: "color",
    typeInput: "color",
  },
  {
    label: "Campo requerido",
    name: "Validate",
    type: "checkbox",
  },
  // {
  //   label: 'Mostrar en la tabla del paso',
  //   name: 'TableDisplay',
  //   type: 'checkbox',
  // },
  {
    label: 'Mostrar al cliente',
    name: 'ClientDisplay',
    type: 'checkbox',
  },
  {
    label: "Campo único por despacho",
    name: "Unique",
    type: "checkbox",
  },
  {
    label: "Bloquear edición",
    name: "lockEdition",
    type: "checkbox",
  },
  {
    label: "Campo auto-generable",
    name: "AutoGenerated",
    type: "checkbox",
    reference: "Type",
    condition: "requestSerialNumber",
  },
  {
    label: "Formato ej: [yyyy][-][correlative:4] -> 2022-0001 ",
    name: "Format",
    placeholder: "[yyyy][-][correlative:4]",
    type: "text",
    typeInput: "input",
    reference: "Type",
    condition: "requestSerialNumber",
  },
];

export const fieldCategoryFormInput = [
  {
    label: "Categoría",
    name: "Category",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "IM4 'DESPACHO AL CONSUMO'",
        value: "IM4 'DESPACHO AL CONSUMO'",
      },
      {
        label: "IMA4 'DESPACHO ANTICIPADO'",
        value: "IMA4 'DESPACHO ANTICIPADO'",
      },
      {
        label: "IMI4 'DESPACHO INMEDIATO'",
        value: "IMI4 'DESPACHO INMEDIATO'",
      },
      {
        label: "IM5 'IMPORTACION TEMPORAL'",
        value: "IM5 'IMPORTACION TEMPORAL'",
      },
      {
        label: "IM6 'REIMPORTACION'",
        value: "IM6 'REIMPORTACION'",
      },
      {
        label: "DEX1 'EXPORTACION DEFINITIVO'",
        value: "DEX1 'EXPORTACION DEFINITIVO'",
      },
      {
        label: "DEX2 'EXPORTACION TEMPORAL'",
        value: "DEX2 'EXPORTACION TEMPORAL'",
      },
    ],
  },
  {
    label: "Estado de documento",
    name: "Status",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Original",
        value: "Original",
      },
      {
        label: "Copia",
        value: "Copia",
      },
      {
        label: "Original o copia",
        value: "Original o copia",
      },
      {
        label: "Fotocopia legalizada",
        value: "Fotocopia legalizada",
      },
      {
        label: "Fotocopia simple",
        value: "Fotocopia simple",
      },
      {
        label: "No aplica",
        value: "No aplica",
      },
    ],
  },
  {
    label: "Habilitar verificación",
    name: "Validation",
    type: "checkbox",
  },
];
export const airlineField = [
  {
    label: "Monto",
    name: "amount",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Tipo de pago",
    name: "pay",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Nº de cuenta",
    name: "account",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Banco",
    name: "bank",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Titular de la cuenta",
    name: "headline",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "NIT/CI",
    name: "citCi",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Correo",
    name: "email",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Contacto",
    name: "contact",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Días de arribo",
    name: "day",
    reference: "type",
    condition: "Aerolínea",
  },
];
