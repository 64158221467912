import * as dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Button from "../../components/Buttons/Button";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { H1, Anchor } from "../../components/Text";
import Texto from "../../components/Text/Texto";
import { Request } from "../../hooks/Request";
import SubModal from "../../components/Modal/SubModal";
import { Tooltip } from "react-tippy";
import ClientList from "./ClientList";
import ModalFullScreen from "../../components/Modal/ModalFullScreen";
import { MessageUnsigned } from "../../components/Forms/FormMessages";
import ListContact from "./ListContact";
import ListInCharge from "./ListInCharge";

const CompanyInfo = (props) => {
  const { reload, onClose, company } = props;
  const [activeForm, setActiveForm] = useState(false);
  const [activeClients, setActiveClients] = useState(false);
  const [infoClient, setInfoClient] = useState();
  const [activeFormFile, setActiveFormFile] = useState();
  const [files, setfiles] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState(false);

  const { data, call: callData } = Request({
    urlApi: `/company/${company.id}`,
  });

  function getClient(client) {
    setInfoClient(client);
  }
  function convertData(convertData) {
    let date = new Date(convertData);
    let fecha = "";
    if (
      date.getDate() !== 0 &&
      date.getMonth() !== 0 &&
      date.getFullYear() !== 0
    ) {
      return (fecha =
        date.getDate() + " / " + date.getMonth() + " / " + date.getFullYear());
    }
    return "No registrado";
  }
  const { data: clients, call } = Request({
    urlApi:
      data?.id === undefined ? null : `company/${data?.id}/ClientsCompany`,
  });

  const envDataClient = (values) => {
    requestAuth("post", `company/${data?.id}/userId/${values?.id}`)
      .then(() => {
        call();
        reload();
        setActiveClients(!activeClients);
        // onClose()
        // setreload(!reload)
        // setShowInfo(!showInfo)
        toast.success("Usuario Añadido a la Compañia");
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    if (values.ExpirationDate) {
      formData.append(
        "ExpirationDate",
        dayjs(values.ExpirationDate).format("DD-MM-YYYY")
      );
    }
    formData.append("File", values.File);
    formData.append("Type", "File");
    formData.append("CompanyId", data.id);
    await requestAuth("post", "/files", formData)
      .then(() => {
        setActiveFormFile(false);
        toast.success("Archivo agregado");
        callData();
      })
      .catch((error) => {
        toast.error(
          error.response.data
            ? error.response.data
            : "Se produjo un error al subir el archivo"
        );
      });
  }

  function getDataFile(value) {
    setfiles(value);
    setDeleteFile(true);
  }

  useEffect(() => {
    if (data !== undefined) {
      call();
    }
  }, [data]);

  return (
    <>
      <H1>Datos del cliente</H1>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Id de compañia:</strong>{" "}
        {data?.num || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Razon Social:</strong>{" "}
        {data?.razonSocial || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">
          Fecha de creacion:
        </strong>{" "}
        {convertData(data?.creationDate || <MessageUnsigned />)}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">NIT:</strong>{" "}
        {data?.nit || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">
          Correo electrónico:
        </strong>{" "}
        {data?.email || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Estado:</strong>{" "}
        {data?.status || <MessageUnsigned />}
      </Texto>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col sm:flex-row shadow-xl">
        <Texto className="font-semibold text-black mr-3">
          Clientes de la compañia:
        </Texto>
        <ul>
          {clients?.length == 0
            ? "Esta compañia no tiene clientes"
            : clients?.map((client, i) => {
                return (
                  <li key={i} className="text-base">
                    - {client?.name} {client?.fatherLastName}{" "}
                    {client?.motherLastName}
                  </li>
                );
              })}
        </ul>
      </div>
      {/* {data !== undefined && <ListContact company={data} />} */}
      {/* {data !== undefined && <ListInCharge company={data} />} */}
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col shadow-xl">
        <Texto className="font-semibold text-black mr-3">
          Archivos de la compañía
        </Texto>
        <ul>
          {data?.files.length == 0 ? (
            <Texto>Sin documentación para mostrar</Texto>
          ) : (
            data?.files.map((file, i) => {
              return (
                <li
                  key={file.id}
                  className="bg-slate-200 my-[10px] flex items-center justify-between text-center font-normal py-[10px] px-[20px]"
                >
                  <Anchor
                    className="text-black w-full mx-[10px] text-[18px] border-b-2 sm:text-[20px] hover:border-[#1502FF] hover:text-[#1502FF] duration-200"
                    href={file.url}
                    target="_blanck"
                  >
                    <FontAwesomeIcon
                      className="text-[20px] mx-[10px]"
                      icon="fa-regular fa-file"
                    />
                    {file?.name}
                  </Anchor>
                  <Tooltip
                    className="sm:mr-[20px]"
                    title="Eliminar archivo"
                    position="top"
                    trigger="mouseenter"
                  >
                    <Button
                      className="w-[30px] flex items-center justify-center m-0"
                      onClick={() => getDataFile(file)}
                    >
                      <FontAwesomeIcon
                        className="text-white text-[18px] sm:text-[20px]"
                        icon="fa-solid fa-trash-can"
                      />
                    </Button>
                  </Tooltip>
                </li>
              );
            })
          )}
        </ul>
      </div>
      <div className="flex justify-center flex-wrap w-full">
        <Tooltip
          className="m-[10px] sm:mr-[20px]"
          title=" Añadir cliente"
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            onClick={() => {
              setActiveClients(!activeClients);
            }}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-users"
            />
          </Button>
        </Tooltip>
        <Tooltip
          className="m-[10px] sm:mr-[20px]"
          title="Adjuntar documento"
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            onClick={() => {
              setActiveFormFile(!activeFormFile);
            }}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-upload"
            />
          </Button>
        </Tooltip>
        <Tooltip
          className="m-[10px] sm:mr-[20px]"
          title="Editar datos de la compañia"
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            onClick={() => {
              setActiveForm(!activeForm);
            }}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-pen-to-square"
            />
          </Button>
        </Tooltip>
        <Tooltip
          className="m-[10px] sm:mr-[20px]"
          title={
            data?.status === "Activo"
              ? "Inhabilitar compañía"
              : "Habilitar compañía"
          }
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            onClick={() => {
              setDeleteCompany(true);
            }}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon={
                data?.status === "Activo"
                  ? "fa-solid fa-eye-slash"
                  : "fa-solid fa-eye"
              }
            />
          </Button>
        </Tooltip>
      </div>

      {/* ELIMINAR ARCHIVO */}

      <ModalFullScreen
        setactivateForm={setDeleteFile}
        activateForm={deleteFile}
      >
        <Texto className="font-bold my-[10px]">
          ¡Se eliminara el archivo {files?.name}!
        </Texto>
        <Button
          className="bg-red-500 text-white flex items-center justify-center my-[20px]"
          onClick={() => {
            requestAuth("delete", `/files/${files.id}`)
              .then(() => {
                callData();
                toast.success("Archivo eliminado");
                setDeleteFile(false);
              })
              .catch(() => {
                toast.error("Error al enviar");
              });
          }}
        >
          Eliminar
        </Button>
      </ModalFullScreen>

      {/* ELIMINAR COMPAÑIA */}

      <ModalFullScreen
        setactivateForm={setDeleteCompany}
        activateForm={deleteCompany}
      >
        <Texto className="font-bold my-[10px]">
          ¡Se {data?.status === "Activo" ? "inhabilitara" : "habilitara"} la
          compañia {data?.razonSocial}!
        </Texto>
        <center>
          <Button
            className="bg-red-500 text-white flex items-center justify-center my-[20px]"
            onClick={() => {
              requestAuth("put", `company/activate/${data.id}`).then(() => {
                call();
                reload();
                onClose();
                toast.success("Compañia eliminada");
                setDeleteCompany(false);
              });
            }}
          >
            {data?.status === "Activo" ? "Inhabilitar" : "Habilitar"}
          </Button>
        </center>
      </ModalFullScreen>

      <SubModal
        setactivateForm={setActiveFormFile}
        activateForm={activeFormFile}
      >
        <H1>Adjuntar documento</H1>
        <FormDefault
          fields={[
            {
              label: "Nombre del documento",
              type: "text",
              name: "Name",
              typeInput: "input",
            },
            {
              label: "Adjuntar un archivo",
              name: "File",
              type: "file",
              typeInput: "file",
            },
            {
              label: "Fecha de vencimiento",
              type: "date",
              name: "ExpirationDate",
              typeInput: "input",
            },
          ]}
          initialValues={{
            Name: "",
            File: "",
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required("Este campo es obligatorio"),
            File: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={onSubmit}
          buttonName="Enviar"
        />
      </SubModal>
      <ClientList
        setActiveClients={setActiveClients}
        setInfoClient={setInfoClient}
        activeClients={activeClients}
        clients={clients}
        data={data}
        call={call}
        reload={reload}
        getClient={getClient}
        envDataClient={envDataClient}
      />
      <SubModal setactivateForm={setActiveForm} activateForm={activeForm}>
        <FormDefault
          tittle="Editar datos de la compañía"
          fields={[
            {
              label: "Id de compañia",
              name: "Num",
              placeholder: "Introduzca id de compañia (opcional)",
              type: "string",
              typeInput: "input",
            },
            {
              label: "Número de Identificación Tributaria (NIT)",
              name: "Nit",
              placeholder: "Introduzca su numero NIT",
              type: "number",
              typeInput: "input",
            },
            {
              label: "Razon social",
              name: "RazonSocial",
              placeholder: "Introdusca la Razon Social",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Correo electrónico",
              name: "Email",
              placeholder: "Introdusca el correo electrónico",
              type: "text",
              typeInput: "input",
            },
          ]}
          initialValues={{
            Id: data?.id || "",
            Nit: data?.nit || "",
            RazonSocial: data?.razonSocial || "",
            Email: data?.email || "",
            Num: data?.num || "",
            // legalRepresentatives: data?.legalRepresentatives,
          }}
          validationSchema={Yup.object().shape({
            Nit: Yup.string().required("Este campo es obligatorio"),
            RazonSocial: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth("put", "company", values)
              .then(() => {
                call();
                reload();
                callData();
                toast.success("Envio exitoso");
                setActiveForm(!activeForm);
                resetForm();
              })
              .catch(() => {
                toast.error("Se ha producido un error al enviar");
              });
          }}
          buttonName="Enviar"
        />
      </SubModal>
    </>
  );
};
export default CompanyInfo;
