import React, { useState } from 'react'
import Button from '../../components/Buttons/Button'
import FormDefault from '../../components/Forms/FormDefault'
import SubModal from '../../components/Modal/SubModal'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { requestAuth } from '../../components/services/RequestService'
import EditInstitution from '../../containers/ModalListInstitution/EditInstitution'
import ListUsers from '../../containers/ModalListInstitution/ListUsers'
import Table from '../../components/Tables/Table'
import { Request } from '../../hooks/Request'

const ListInstitution = () => {
  const [getInstitution, setGetInstitution] = useState()
  const [reload, setreload] = useState(false)
  const [createForm, setcreateForm] = useState(false)
  const [modalInstitution, setmodalInstitution] = useState(false)
  const [listUsersModal, setlistUsersModal] = useState(false)

  function getDataInstitution(value) {
    setGetInstitution(value)
    // setlistUsersModal(true)
  }
  function listUsersInstitution(value) {
    setGetInstitution(value)
    setlistUsersModal(true)
  }
  function editInstitution(value) {
    setreload(!reload)
    setGetInstitution(value)
    setmodalInstitution(true)
  }

  const [pageNumber, setPageNumber] = useState(1)
  //   const [btnSelect, setBtnSelect] = useState(false)
  const { data, call } = Request({
    urlApi: `institution?PageNumber=${pageNumber}&`,
  })

  return (
    <div className="shadow-inner  flex flex-col h-auto items-center p-[20px] sm:p-[50px]">
      <Button className="sm:mb-[30px]" onClick={() => setcreateForm(true)}>
        CREAR NUEVA INSTITUCIÓN
      </Button>

      {data !== undefined ? (
        <Table
          showField={listUsersInstitution}
          onClick={getDataInstitution}
          data={data}
          header={[
            {
              name: 'name',
              label: 'Instituciones',
              filter: true,
            },
            {
              icon: `fa-file-pen`,
              label: 'Editar',
              status: true,
              action: editInstitution,
            },
          ]}
          statusPaination={false}
          edit={true}
        />
      ) : null}

      {/* FORM CREATE */}

      <SubModal setactivateForm={setcreateForm} activateForm={createForm}>
        <FormDefault
          tittle="Crear nueva institución"
          fields={[
            {
              label: 'Nombre de institución',
              name: 'Name',
              placeholder: 'Introduzca el nombre de la nueva institución',
              type: 'text',
              typeInput: 'input',
            },
          ]}
          initialValues={{
            Name: '',
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required('Este campo es obligatorio'),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth('post', 'institution', values)
              .then(() => {
                setcreateForm(false)
                setreload(!reload)
                toast.success('Institución creada correctamente')
                resetForm()
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : 'Se produjo un error al crear',
                )
              })
          }}
          buttonName="Enviar"
        />
      </SubModal>

      {/* LIST USERS */}

      <SubModal
        setactivateForm={setlistUsersModal}
        activateForm={listUsersModal}
      >
        <ListUsers />
      </SubModal>

      {/* EDIT INSTITUTION */}

      {modalInstitution ? (
        <SubModal
          setactivateForm={setmodalInstitution}
          activateForm={modalInstitution}
        >
          <EditInstitution
            call={call}
            active={setmodalInstitution}
            data={getInstitution}
            reload={reload}
            setreload={setreload}
          />
        </SubModal>
      ) : null}
    </div>
  )
}

export default ListInstitution
