import { H3 } from "../../components/Text";
import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";

import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";

const History = () => {
  const { rolId } = useParams();
  const { openDialog, dialogClose } = useDialog();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateArchive() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de archivo?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/gamarra/archiveData"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <a
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </a>
        </center>
        <br />
      </div>
    );
  }

  return (
    <CallServiceTable
      urlApi="/procedure/user"
      filters={[
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
      ]}
      component={() => {
        return (
          rolId === "7" && (
            <Button className="text-lg py-3" onClick={generateArchive}>
              <i className="fas fa-archive"></i>
            </Button>
          )
        );
      }}
      addFilters={[
        {
          name: "procedureNumber",
          label: "Tramite",
          filter: true,
        },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "clientCode",
          label: "Cod. Cliente",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
          filter: true,
        },
        {
          name: "invoice",
          label: "Factura",
          filter: true,
        },
        {
          name: "docEmbarque",
          label: "Embarque",
          filter: true,
        },
        {
          name: "product",
          label: "Mercadería",
          filter: true,
        },
      ]}
      header={[
        {
          name: "numberString",
          label: "Trámite",
        },
        {
          name: "internCode",
          label: "Nro. interno",
        },
        {
          name: "nroCliente",
          label: "Cod. Cliente",
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
        },
        {
          name: "nroEmbarque",
          label: "Embarque",
        },
        {
          name: "billNumber",
          label: "Factura",
        },
        {
          name: "companyName",
          label: "Cliente",
        },
        {
          name: "customsClearance",
          label: "Aduana",
        },
        {
          name: "canalColor",
          label: "Canal",
        },
        {
          name: "procedureTypeName",
          label: "Patrón",
        },
        {
          name: "creationDate",
          label: "Fecha de inicio",
          type: "date",
        },
        {
          name: "currentStep",
          label: "Progreso",
          type: "custom",
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return "Terminado";
            }
            return value;
          },
        },
        {
          name: "descripcionMercancias",
          label: "Mercaría",
        },
        {
          name: "Reports",
          type: "action",
          label: "Reportes",
          sticky: true,
          actions: [
            {
              label: "Generar informe de la recepción de documentos",
              icon: "fas fa-tasks",
              action: listDocumentPdf,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar liquidación - proforma",
              icon: "fas fa-coins",
              action: generateSettlementProforma,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar hoja de ruta",
              icon: "fas fa-route",
              action: generateRoadmap,
              color: "text-[#1d4ed8]",
            },
          ],
        },
        // {
        //   name: 'procedureTypeName',
        //   label: 'Tipo de Proceso',
        //   filter: false,
        // },
      ]}
      addUrl={`designation=liquidator&rolId=${rolId}`}
      statusPaination={true}
    />
  );
};

export default History;
