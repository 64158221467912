import { Route, Routes } from "react-router-dom";
import { validateArray } from "../utils/validate";
import Login from "../pages/Login";
import Template from "../template/Template";
import Users from "../pages/user/Users";
import Procedure from "../pages/systems/Procedures";
import Company from "../pages/company/Company";
import Summary from "../pages/Summary/Summary";
import Menus from "../pages/menus/Menus";
import Roles from "../pages/roles/Roles";
import ClientList from "../pages/clientList/ClientList";
import StepsTramites from "../pages/steps/StepsTramites";
import ManegeProcedure from "../pages/manageProcedures/ManegeProcedure";
import Management from "../pages/Management/Management";
import ListProcedures from "../pages/listProcedures/ListProcedures";
import LendingList from "../pages/LendingList/LendingList";
import MyLoans from "../pages/MyLoans/MyLoans";
import Regularization from "../pages/Regularization/Regularization";
import ListInstitution from "../pages/ListInstitution/ListInstitution";
import History from "../pages/History/History";
import ProcedureTypes from "../pages/ProcedureTypes/ProcedureTypes";
import CompleteDocuments from "../pages/CompleteDocuments/CompleteDocuments";
import GenerateExcel from "../pages/generateExcel/GenerateExcel";
import Settlement from "../pages/Settlement/Settlement";
import DropdownManagement from "../pages/DropdownManagement/DropdownManagement";
import FieldManager from "../pages/FieldManager/FieldManager";
import ListProceduresView from "../pages/ListProceduresView/ListProceduresView";
import ViewStep from "../pages/ViewStep/ViewStep";
import ManageInstitution from "../pages/ManageInstitution/ManageInstitution";
import ManageButtons from "../pages/ManageButtons/ManageButtons";
import ManageAppointments from "../pages/ManageAppointments/ManageAppointments";
import ProceduresInCharge from "../pages/ProceduresInCharge/ProceduresInCharge";
import AirWaybills from "../pages/AirWaybills/AirWaybills";
import ProformRegister from "../pages/ProformRegister/ProformRegister";
import ProofDeliveries from "../pages/ProofDeliveries/ProofDeliveries";

const routeAdminUser = [
  {
    path: "UserRegister",
    element: <Users />,
  },
  {
    path: "CompanyRegister",
    element: <Company />,
  },
  {
    path: "MenuRegister",
    element: <Menus />,
  },
  {
    path: "RolRegister",
    element: <Roles />,
  },
];
const routeClient = [
  {
    path: "ClientSummary",
    element: <Summary />,
  },
  {
    path: "ClientList",
    element: <ClientList />,
  },
];
const routeAdminForms = [
  {
    path: "HandleSteps",
    element: <Procedure />,
  },
  {
    path: "ProcedureTypes",
    element: <ProcedureTypes />,
  },
  {
    path: "dropdown-management",
    element: <DropdownManagement />,
  },
  {
    path: "fieldManager",
    element: <FieldManager />,
  },
  {
    path: "view-step",
    element: <ViewStep />,
  },
  {
    path: "manage-buttons",
    element: <ManageButtons />,
  },
];
const routeSettlement = [
  {
    path: "Settlement",
    element: <Settlement />,
  },
];
const routeManagement = [
  {
    path: "Management",
    element: <Management />,
  },
];
const routeAdminProcedure = [
  {
    path: "ManageProcedures",
    element: <ManegeProcedure />,
  },
  {
    path: "HistoryProcedure",
    element: <ListProcedures />,
  },
];
const routeLoans = [
  {
    path: "LendingList",
    element: <LendingList />,
  },
  {
    path: "MyLoans",
    element: <MyLoans />,
  },
];
const routeRegularization = [
  {
    path: "regularization-imp",
    element: <Regularization type="regularizado" procedureTypeId={1} />,
  },
  {
    path: "regularization-exp",
    element: <Regularization type="regularizado" procedureTypeId={2} />,
  },
];
const routeInstitution = [
  {
    path: "ListInstitution",
    element: <ListInstitution />,
  },
];
const routeCompleteDocuments = [
  {
    path: "CompleteDocuments",
    element: <CompleteDocuments />,
  },
  {
    path: "air-waybills",
    element: <AirWaybills />,
  },
];

const routeProform = [
  {
    path: "proform-accountant",
    element: <ProformRegister rol="accountant" />,
  },
  {
    path: "proform-liquidator",
    element: <ProformRegister rol="liquidator" />,
  },
];

const routeListProcedures = [
  {
    path: "list-procedures",
    element: <ListProceduresView />,
  },
];
const routeManageInstitution = [
  {
    path: "manage-institution",
    element: <ManageInstitution />,
  },
];
const routeCharge = [
  {
    path: "procedures-in-charge",
    element: <ProceduresInCharge />,
  },
];

const RouteMaster = () => {
  const createRouter = [
    {
      path: "/rol/:rolId/menu",
      index: <Template />,
      children: [
        ...routeAdminUser,
        ...routeClient,
        ...routeAdminForms,
        ...routeManagement,
        ...routeAdminProcedure,
        ...routeLoans,
        ...routeRegularization,
        ...routeInstitution,
        ...routeCompleteDocuments,
        ...routeSettlement,
        ...routeProform,
        ...routeListProcedures,
        ...routeManageInstitution,
        ...routeCharge,
        {
          path: "manage-appointments",
          element: <ManageAppointments />,
        },
        {
          path: "proof-deliveries",
          element: <ProofDeliveries />,
        },
      ],
    },
    {
      path: "rol/:rolId/procedure-type/:typeId/step/",
      index: <Template />,
      children: [
        {
          path: ":step",
          element: <StepsTramites />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document",
          element: <GenerateExcel />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document/review",
          element: <GenerateExcel review={true} />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document/answer",
          element: <GenerateExcel review={true} />,
        },
      ],
    },
    {
      path: "rol/:rolId/",
      index: <Template />,
      children: [
        {
          path: "history-procedure",
          element: <History />,
        },
      ],
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/recover" element={<Login />} />
      <Route path="/password-recovery/:verifyKey" element={<Login />} />
      {createRouter.map((route, index) => (
        <Route key={index} path={route.path}>
          {route.index && <Route index element={route.index} />}
          {validateArray(route.children)
            ? route.children.map((children, j) => (
                <Route
                  key={j}
                  path={children.path}
                  element={
                    <Template path={children.path} main={children.element} />
                  }
                />
              ))
            : null}
          <Route path="*" element={<></>} />
        </Route>
      ))}
    </Routes>
  );
};
export default RouteMaster;
