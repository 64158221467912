import * as Yup from 'yup'
import { toast } from 'react-toastify'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const FormAddValidation = (props) => {
  const { step, reload, onClose } = props

  return (
    <FormDefault
      tittle="Crear una validación"
      fields={[
        {
          label: 'Seleccione el campo',
          name: 'FieldId',
          urlApi: 'field',
          typeInput: 'selectTable',
          labelSelect: 'label',
        },
        {
          label: 'Validación',
          name: 'operador',
          type: 'select',
          typeInput: 'select',
          options: [
            {
              label: 'Igual',
              value: 'Igual',
            },
            {
              label: 'Distinto',
              value: 'Distinto',
            },
            {
              label: 'Mayor que',
              value: 'Menor que',
            },
          ],
        },
        {
          label: 'Validar',
          name: 'validation',
          placeholder: 'Validar',
          type: 'text',
          typeInput: 'input',
        },
      ]}
      initialValues={{
        operador: 'Igual',
      }}
      validationSchema={Yup.object().shape({
        operador: Yup.string().required('Este campo es obligatorio'),
        validation: Yup.string().required('Este campo es obligatorio'),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth('post', '/processStep/validator', {
          ...values,
          FieldId: values.FieldId.id,
          skip: false,
          ProcessStepId: step.id,
        })
          .then(() => {
            toast.success('Envio exitoso')
            reload()
            onClose()
          })
          .catch(() => {
            toast.error('Se produjo un error al enviar')
          })
      }}
      buttonName="Enviar"
    />
  )
}

export default FormAddValidation
