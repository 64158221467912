import React, { useState } from 'react'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Button from '../../components/Buttons/Button'
import { H3 } from '../../components/Text'
import { useModal } from '../../contexts/ModalContext'
import UserInfo from '../../containers/ModalsUsers/InfoUsers'
import { useDialog } from '../../contexts/DialogContext'
import { DialogConfirmation } from '../../components/Modal'

const Users = () => {
  const { openModal, onClose } = useModal()
  const { openDialog, dialogClose } = useDialog()
  function handleNewUser(tableReload) {
    openModal(<FormAddUser reload={tableReload} onClose={onClose} />)
  }
  function handleDetailUser(user, tableReload) {
    openModal(
      <UserInfo userId={user.id} reload={tableReload} onClose={onClose} />,
    )
  }
  function sendEmail(user) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres reenviar las credenciales?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/auth/user/${user.id}/credentials`}
        texBtn="Reenviar"
      />,
    )
  }
  return (
    <>
      <CallServiceTable
        onClick={handleDetailUser}
        urlApi="/users/ListUsers"
        addFilters={[
          {
            name: 'firstName',
            label: 'Nombres',
            filter: true,
          },
        ]}
        component={({ reload }) => {
          return (
            <Button
              className="text-lg py-3"
              onClick={() => handleNewUser(reload)}
            >
              Crear usuario <i className="fa-solid fa-plus"></i>
            </Button>
          )
        }}
        header={[
          {
            name: 'name',
            label: 'Nombres',
            filter: false,
          },
          {
            name: 'fatherLastName',
            label: 'Apellido Paterno',
            filter: true,
          },
          {
            name: 'motherLastName',
            label: 'Apellido Materno',
            filter: true,
          },
          {
            name: 'email',
            label: 'Correo',
            filter: false,
          },
          {
            name: 'status',
            label: 'Estado',
            filter: false,
          },
          {
            name: 'edit',
            type: 'action',
            label: 'Enviar credenciales',
            actions: [
              {
                label: 'Reenviar credenciales',
                icon: 'fas fa-mail-bulk',
                action: sendEmail,
                color: 'text-[#1d4ed8]',
              },
            ],
          },
        ]}
        addUrl={`userId=${localStorage.getItem('id')}`}
        filters={[
          {
            url: '/rol',
            name: 'RolName',
            default: 'Todos los roles',
          },
        ]}
      />
    </>
  )
}

const FormAddUser = (props) => {
  const { onClose, reload } = props

  return (
    <div>
      <FormDefault
        tittle="Crear nuevo usuario"
        fields={[
          {
            label: 'Nombre',
            name: 'Name',
            placeholder: 'Introduzca su nombre',
            type: 'text',
            typeInput: 'input',
          },
          {
            label: 'Apellido paterno',
            name: 'FatherLastName',
            placeholder: 'Introduzca su apellido paterno',
            type: 'text',
            typeInput: 'input',
          },
          {
            label: 'Apellido materno',
            name: 'MotherLastName',
            placeholder: 'Introduzca su apellido materno',
            type: 'text',
            typeInput: 'input',
          },
          {
            label: 'Nombre de usuario',
            name: 'Username',
            placeholder: 'Introduzca su nombre de usuario',
            type: 'text',
            typeInput: 'input',
          },
          {
            label: 'Numero de celular',
            name: 'CellphoneNumber',
            placeholder: 'Introduzca su numero de celular',
            type: 'number',
            typeInput: 'input',
          },
          {
            label: 'Carnet de identidad',
            name: 'Ci',
            placeholder: 'Introduzca su numero de carnet de identidad',
            type: 'number',
            typeInput: 'input',
          },
          {
            label: 'Ciudad de residencia',
            name: 'City',
            type: 'select',
            typeInput: 'select',
            options: [
              {
                label: 'Santa cruz',
                value: 'Santa cruz',
              },
              {
                label: 'La paz',
                value: 'La paz',
              },
              {
                label: 'Cochabamba',
                value: 'Cochabamba',
              },
              {
                label: 'Sucre',
                value: 'Sucre',
              },
              {
                label: 'Oruro',
                value: 'Oruro',
              },
              {
                label: 'Tarija',
                value: 'Tarija',
              },
              {
                label: 'Beni',
                value: 'Beni',
              },
              {
                label: 'Pando',
                value: 'Pando',
              },
              {
                label: 'Potosi',
                value: 'Potosi',
              },
            ],
          },
          {
            label: 'Email',
            name: 'Email',
            placeholder: 'correo@gmail.com',
            type: 'email',
            typeInput: 'input',
          },
          {
            label: 'Contraseña',
            name: 'Password',
            placeholder: 'Introduzca su contraseña',
            type: 'password',
            typeInput: 'input',
          },
          {
            label: 'Numero de telefono',
            name: 'PhoneNumber',
            placeholder: 'Introduzca su numero de telefono',
            type: 'number',
            typeInput: 'input',
          },
          {
            label: 'Estado de usuario',
            name: 'Status',
            type: 'select',
            typeInput: 'select',
            options: [
              {
                label: 'Activo',
                value: 'Activo',
              },
              {
                label: 'Inactivo',
                value: 'Inactivo',
              },
            ],
          },
          {
            label: 'Seleccione los roles',
            name: 'Rol',
            placeholder: 'Introduzca su nombre de usuario',
            type: 'checked',
            typeInput: 'checkboxes',
            urlApi: '/rol',
            value: 'id',
          },
        ]}
        initialValues={{
          CellphoneNumber: '',
          Ci: '',
          City: 'Santa cruz',
          Email: '',
          FatherLastName: '',
          MotherLastName: '',
          Name: '',
          Password: '',
          PhoneNumber: '',
          Status: 'Activo',
          Username: '',
          Rol: [],
        }}
        validationSchema={Yup.object().shape({
          Ci: Yup.string().required('Este campo es obligatorio'),
          City: Yup.string().required('Este campo es obligatorio'),
          Email: Yup.string()
            .email('Formato de correo invalido')
            .required('Este campo es obligatorio'),
          FatherLastName: Yup.string().required('Este campo es obligatorio'),
          MotherLastName: Yup.string().required('Este campo es obligatorio'),
          Name: Yup.string().required('Este campo es obligatorio'),
          Password: Yup.string().required('Este campo es obligatorio'),
          Username: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={async (values) => {
          await requestAuth('post', 'auth/Register', {
            ...values,
            Rol: values.Rol.map((item) => ({
              Id: item,
            })),
          })
            .then(() => {
              onClose()
              reload()
              toast.success('Envio exitoso')
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : 'Se produjo un error al enviar',
              )
            })
        }}
        buttonName="Enviar"
      />
    </div>
  )
}

export default Users
