import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../components/Buttons/Button'
import { requestAuth } from '../../components/services/RequestService'
import 'react-toastify/dist/ReactToastify.css'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import SubModal from '../../components/Modal/SubModal'
import FormDefault from '../../components/Forms/FormDefault'
import * as Yup from 'yup'
import UserInfo from '../ModalsUsers/InfoUsers'

const ListUsers = () => {
  const [activateForm, setactivateForm] = useState(false)
  const [reload, setreload] = useState(false)
  const [userId, setUserId] = useState(undefined)
  const [showInfo, setShowInfo] = useState(false)
  function getUser(user) {
    setShowInfo(!showInfo)
    setUserId(user.id)
  }
  exitEsc(setactivateForm)
  return (
    <>
      <Button
        className="sm:mb-[30px]"
        onClick={() => setactivateForm(!activateForm)}
      >
        CREAR NUEVO USUARIO
      </Button>
      <CallServiceTable
        reload={reload}
        responsive={true}
        onClick={getUser}
        urlApi="/users/ListUsers"
        addFilters={[
          {
            name: 'firstName',
            label: 'Nombres',
            filter: true,
          },
        ]}
        header={[
          {
            name: 'name',
            label: 'Nombres',
            filter: false,
          },
          {
            name: 'fatherLastName',
            label: 'Apellido Paterno',
            filter: true,
          },
          {
            name: 'motherLastName',
            label: 'Apellido Materno',
            filter: true,
          },
          {
            name: 'email',
            label: 'Correo',
            filter: false,
          },
          {
            name: 'status',
            label: 'Estado',
            filter: false,
          },
        ]}
        addUrl={`userId=${localStorage.getItem('id')}&RolName=usersAdmin`} //colocar lo del
        // filters={[
        //   {
        //     url: '/rol',
        //     name: 'RolName',
        //   },
        // ]}
        statusPaination={true}
        showTable={false}
      />
      <SubModal setactivateForm={setactivateForm} activateForm={activateForm}>
        <FormDefault
          tittle="Crear nuevo usuario"
          fields={[
            {
              label: 'Nombre',
              name: 'Name',
              placeholder: 'Introduzca su nombre',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Apellido paterno',
              name: 'FatherLastName',
              placeholder: 'Introduzca su apellido paterno',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Apellido materno',
              name: 'MotherLastName',
              placeholder: 'Introduzca su apellido materno',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Nombre de usuario',
              name: 'Username',
              placeholder: 'Introduzca su nombre de usuario',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Numero de celular',
              name: 'CellphoneNumber',
              placeholder: 'Introduzca su numero de celular',
              type: 'number',
              typeInput: 'input',
            },
            {
              label: 'Carnet de identidad',
              name: 'Ci',
              placeholder: 'Introduzca su numero de carnet de identidad',
              type: 'number',
              typeInput: 'input',
            },
            {
              label: 'Ciudad de residencia',
              name: 'City',
              type: 'select',
              typeInput: 'select',
              options: [
                {
                  label: 'Santa cruz',
                  value: 'Santa cruz',
                },
                {
                  label: 'La paz',
                  value: 'La paz',
                },
                {
                  label: 'Cochabamba',
                  value: 'Cochabamba',
                },
                {
                  label: 'Sucre',
                  value: 'Sucre',
                },
                {
                  label: 'Oruro',
                  value: 'Oruro',
                },
                {
                  label: 'Tarija',
                  value: 'Tarija',
                },
                {
                  label: 'Beni',
                  value: 'Beni',
                },
                {
                  label: 'Pando',
                  value: 'Pando',
                },
                {
                  label: 'Potosi',
                  value: 'Potosi',
                },
              ],
            },
            {
              label: 'Email',
              name: 'Email',
              placeholder: 'correo@gmail.com',
              type: 'email',
              typeInput: 'input',
            },
            {
              label: 'Contraseña',
              name: 'Password',
              placeholder: 'Introduzca su contraseña',
              type: 'password',
              typeInput: 'input',
            },
            {
              label: 'Numero de telefono',
              name: 'PhoneNumber',
              placeholder: 'Introduzca su numero de telefono',
              type: 'number',
              typeInput: 'input',
            },
            {
              label: 'Estado de usuario',
              name: 'Status',
              type: 'select',
              typeInput: 'select',
              options: [
                {
                  label: 'Activo',
                  value: 'Activo',
                },
                {
                  label: 'Inactivo',
                  value: 'Inactivo',
                },
              ],
            },
          ]}
          initialValues={{
            CellphoneNumber: '',
            Ci: '',
            City: 'Santa cruz',
            Email: '',
            FatherLastName: '',
            MotherLastName: '',
            Name: '',
            Password: '',
            PhoneNumber: '',
            Status: 'Activo',
            Username: '',
            Rol: [{ Id: '1' }],
          }}
          validationSchema={Yup.object().shape({
            Ci: Yup.string().required('Este campo es obligatorio'),
            City: Yup.string().required('Este campo es obligatorio'),
            Email: Yup.string()
              .email('Formato de correo invalido')
              .required('Este campo es obligatorio'),
            FatherLastName: Yup.string().required('Este campo es obligatorio'),
            MotherLastName: Yup.string().required('Este campo es obligatorio'),
            Name: Yup.string().required('Este campo es obligatorio'),
            Password: Yup.string().required('Este campo es obligatorio'),
            Username: Yup.string().required('Este campo es obligatorio'),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth('post', 'auth/Register', values)
              .then(() => {
                setreload(true)
                setactivateForm(!activateForm)
                toast.success('Envio exitoso')
                resetForm()
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : 'Se produjo un error al enviar',
                )
              })
          }}
          buttonName="Enviar"
        />
      </SubModal>
      {userId !== undefined && (
        <UserInfo
          setreload={setreload}
          reload={reload}
          userId={userId}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
        />
      )}
    </>
  )
}

export default ListUsers

const exitEsc = (setClose) => {
  document.addEventListener('keydown', (event) => {
    if (event.which === 27 && event.keyCode === 27) {
      setClose(false)
    }
  })
}
