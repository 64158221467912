import * as Yup from "yup";
import { Field } from "formik";
import { H3, Label, Anchor } from "../Text";
import { Button } from "../Buttons";
import { toast } from "react-toastify";
import Circle from "../Animation/Circle";
import { Request } from "../../hooks/Request";
import { DialogConfirmation } from "../Modal";
import FormDefault from "../Forms/FormDefault";
import TableComplete from "../Tables/TableComplete";
import { useDialog } from "../../contexts/DialogContext";
import { requestAuth } from "../services/RequestService";

const ControlField = ({ field, fieldDelivery = false }) => {
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call } = Request({
    urlApi: `/fileState/procedure/${field.id}/field/${field.fieldId}`,
  });

  function handleForm(data) {
    openDialog(
      <FormAdd
        data={data}
        onClose={dialogClose}
        reload={call}
        field={field}
        fieldDelivery={fieldDelivery}
      />
    );
  }

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el archivo?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/fileState/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  const headerField = fieldDelivery
    ? [
        {
          name: "number",
          label: "Número de referencia",
        },
        {
          name: "placeOfDelivery",
          label: "Lugar de entrega",
        },
        {
          name: "dateOfDelivery",
          label: "Fecha de entrega",
          type: "date",
        },
      ]
    : [
        {
          name: "description",
          label: "Descripción",
        },
        {
          name: "creationDate",
          label: "Fecha",
          type: "date",
        },
      ];

  return (
    <div
      className={`flex ${
        field.type === "checkbox" ? "flex-row items-center gap-x-8" : "flex-col"
      } bg-gray-300/40 p-2 rounded-md`}
    >
      <Label htmlFor={field.name} className={`${field.className} my-0`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <Label className="text-base font-normal my-1">Descripción</Label>
      <Field
        as="textarea"
        autoComplete="current-password"
        className="md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] placeholder-black placeholder:text-[15px] placeholder:opacity-50 placeholder:font-normal px-[17px] py-[15px] sm:py-[20px] font-light text-[15px] bg-white/50 md:bg-white/75 "
        type={field.type}
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
      />
      <div className="flex justify-between my-2">
        <Label className="text-base font-normal my-1 mt-3">
          Archivos adjuntos
        </Label>
        <Button
          type="button"
          className="px-2 py-1"
          onClick={() => handleForm({})}
        >
          <i className="fas fa-plus p-0 m-0"></i>
        </Button>
      </div>
      {!loading ? (
        <Circle />
      ) : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "url",
                label: "Enlace",
                type: "custom",
                builder: (item) => {
                  if (item) {
                    return (
                      <Anchor
                        href={item}
                        className="text-[#1d4ed8] underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Abrir
                      </Anchor>
                    );
                  } else {
                    return "-";
                  }
                },
              },
              ...headerField,
              {
                name: "add",
                type: "action",
                label: "Opciones",
                sticky: true,
                actions: [
                  {
                    label: "Añadir",
                    icon: "fas  fa-edit",
                    action: handleForm,
                    color: "text-[#1d4ed8]",
                  },
                  {
                    label: "Añadir",
                    icon: "fas fa-trash",
                    action: handleDelete,
                    color: "text-red-500",
                  },
                ],
              },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  );
};

const listTariffItem = [
  {
    label: "00012022 - Bus Universal en Serie",
    value: "00012022",
    url: "https://res.cloudinary.com/pataformacgi/image/upload/v1673967982/Trazo/Informes%20merciologicos/2INFOR_1_yte4xr.pdf",
  },
  {
    label: "00122022 - CHUPETE BOM BOM BUM SABOR FRESA",
    value: "00122022",
    url: "https://res.cloudinary.com/pataformacgi/image/upload/v1673967980/Trazo/Informes%20merciologicos/3INFOR_1_hsqqmi.pdf",
  },
  {
    label: "00112022 - EL CARAMELO BLANDO -MILLOW",
    value: "00112022",
    url: "https://res.cloudinary.com/pataformacgi/image/upload/v1673967981/Trazo/Informes%20merciologicos/4INFOR_1_vx1pxv.pdf",
  },
  { label: "Otro", value: "0", url: null },
];

const FormAdd = ({ data, onClose, reload, field, fieldDelivery }) => {
  async function uploadFile(values) {
    if (values.tariffItem && values.tariffItem !== "0") {
      const item = listTariffItem.filter(
        (data) => data.value === values.tariffItem
      );
      onSubmit({
        ...values,
        url: item[0].url,
        name: values.tariffItem,
        description: item[0].label,
      });
    } else if (typeof values.url === "string") {
      onSubmit({ ...values, name: values.tariffItem });
    } else {
      let formData = new FormData();
      formData.append("Name", field.label);
      formData.append("File", values.url);
      formData.append("originalName", values.url.name);
      formData.append("Status", "verified");
      formData.append("ProcedureId", field.id);
      await requestAuth("post", "/files", formData)
        .then((res) => {
          onSubmit({
            ...values,
            url: res.data.url,
            name: values.tariffItem ? values.tariffItem : field.label,
          });
        })
        .catch(() => {
          toast.error("Se produjo un error al subir el archivo");
        });
    }
  }

  function editOnSubmit(values) {
    if (values.tariffItem) {
      const item = listTariffItem.filter(
        (data) => data.value === values.tariffItem
      );
      onSubmit({
        ...values,
        url: item[0].url,
        name: values.tariffItem,
        description: item[0].label,
      });
    } else {
      onSubmit(values);
    }
  }

  async function onSubmit(values) {
    await requestAuth(
      data.id ? "put" : "post",
      data.id
        ? `/fileState/${data.id}`
        : `/fileState/procedure/${field.id}/field/${field.fieldId}`,
      values
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  return (
    <FormDefault
      tittle={`Adicionar archivo`}
      fields={
        field.fieldId === 233
          ? [
              {
                label: "Partida arancelaria",
                name: "tariffItem",
                type: "select",
                typeInput: "select",
                options: listTariffItem,
              },
              {
                label: "Descripción del archivo",
                name: "description",
                type: "textarea",
                typeInput: "textarea",
                reference: "tariffItem",
                condition: "0",
              },
              {
                label: "Archivo",
                name: "url",
                type: "file",
                typeInput: "file",
                reference: "tariffItem",
                condition: "0",
              },
            ]
          : fieldDelivery
          ? [
              {
                label: "Archivo",
                name: "url",
                type: "file",
                typeInput: "file",
              },
              {
                label: "Número de referencia",
                name: "number",
                placeholder: "Número de referencia",
              },
              {
                label: "Lugar de entrega",
                name: "placeOfDelivery",
                placeholder: "Lugar de entrega",
              },
              {
                label: "Fecha de entrega",
                name: "dateOfDelivery",
                placeholder: "Fecha de entrega",
                type: "date",
              },
            ]
          : [
              {
                label: "Archivo",
                name: "url",
                type: "file",
                typeInput: "file",
              },
              {
                label: "Descripción del archivo",
                name: "description",
                placeholder: "Descripción",
                type: "textarea",
                typeInput: "textarea",
              },
            ]
      }
      initialValues={{
        tariffItem:
          field.fieldId === 233 ? (data.name ? data.name : "401") : null,
        url: data.url ?? null,
        description: data.description ?? "",
        number: data.number ?? "",
        placeOfDelivery: data.placeOfDelivery ?? "",
        dateOfDelivery: data.dateOfDelivery ?? "",
      }}
      validationSchema={Yup.object().shape(
        field.fieldId === 233
          ? {
              url: Yup.mixed().when("tariffItem", {
                is: "0",
                then: Yup.mixed().required("Campo requerido."),
              }),
              description: Yup.string().when("tariffItem", {
                is: "0",
                then: Yup.string().required("Campo requerido."),
              }),
            }
          : {
              url: Yup.mixed().required("Campo requerido.").nullable(),
            }
      )}
      onSubmit={(values) =>
        typeof values.url === "string" && values.tariffItem !== "0"
          ? editOnSubmit(values)
          : uploadFile(values)
      }
      buttonName="Registrar"
    />
  );
};

export default ControlField;
