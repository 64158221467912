import Recurso_3logo from "../assets/logo45-5.png";
import FormDefault from "../components/Forms/FormDefault";
import * as Yup from "yup";
import { request } from "../components/services/RequestService";
import { useAuth } from "../contexts/AuthContext";
import { validateArray } from "../utils/validate";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { H1, H3 } from "../components/Text";
import { RequestNoAuth } from "../hooks/RequestNoAuth";
import React from "react";
import styled from "styled-components";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const params = useParams();

  const { data, loading } = RequestNoAuth({
    urlApi: "/institutionParams/generalData",
  });

  function handleToJson(list) {
    if (validateArray(list)) {
      let auxJson = {};
      list.forEach((element) => {
        auxJson = { ...auxJson, [element.code]: element.value };
      });
      return auxJson;
    }
    return {};
  }

  if (!loading) {
    return null;
  } else if (location.pathname === "/") {
    return (
      <FormLogin
        title="Inicio de sesión"
        fields={[
          {
            label: "Nombre de usuario",
            name: "UsernameOrEmail",
            typeInput: "input",
            type: "text",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
          {
            label: "Contraseña",
            name: "password",
            typeInput: "input",
            type: "password",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
        ]}
        validationSchema={{
          UsernameOrEmail: Yup.string().required("Este campo es obligatorio"),
          password: Yup.string().required("Este campo es obligatorio"),
        }}
        onSubmit={async (values) => {
          await request("post", "/auth/login", values)
            .then(function (response) {
              localStorage.setItem("token_seguridad", response.data.token);
              localStorage.setItem("id", response.data.user.id);
              setUser({
                user: response.data.user,
                rols: response.data.rols,
                company: response.data.companys,
                institutionParam: response.data.institutionParam,
              });
              const rol = response.data.rols[0];
              navigate(`/rol/${rol.id}/menu`);
              toast.success("Inicio exitoso");
            })
            .catch((err) => {
              toast.error("Tu nombre de usuario o contraseña son incorrectas");
            });
        }}
        buttonName="INGRESAR"
        data={handleToJson(data)}
      />
    );
  } else if (location.pathname === "/recover") {
    return (
      <FormLogin
        title="Recuperar Contraseña"
        fields={[
          {
            label: "Nombre de usuario o correo",
            name: "UsernameOrEmail",
            typeInput: "input",
            type: "text",
            placeholder: "Introduzca su nombre de usuario o correo",
            className: `text-[15px] mt-[5px]`,
          },
        ]}
        validationSchema={{
          UsernameOrEmail: Yup.string().required("Este campo es obligatorio"),
        }}
        onSubmit={async (values) => {
          await request("post", "/auth/recoveryAccount", values)
            .then(() => {
              navigate("/");
              toast.success("Se te envió un correo electrónico");
            })
            .catch((err) => {
              console.log(err);
              toast.error("No se pudo realizar la acción");
            });
        }}
        buttonName="RECUPERAR"
        data={handleToJson(data)}
        redirect={
          <Link to="/">
            Volver al <span>Inicio de sesión</span>
          </Link>
        }
      />
    );
  } else if (location.pathname.includes("password-recovery")) {
    return (
      <FormLogin
        title="Restablecer Contraseña"
        fields={[
          {
            label: "Nombre de usuario",
            name: "UsernameOrEmail",
            typeInput: "input",
            type: "text",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
          {
            label: "Contraseña",
            name: "password",
            typeInput: "input",
            type: "password",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
        ]}
        onSubmit={async (values) => {
          await request("post", "/auth/verifyRecovery", {
            ...values,
            verifyKey: params.verifyKey,
          })
            .then(() => {
              navigate("/");
              toast.success(
                "Se cambio tu contraseña ahora puedes iniciar sesión"
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error("No se pudo realizar la acción");
            });
        }}
        validationSchema={{
          UsernameOrEmail: Yup.string().required("Este campo es obligatorio"),
          password: Yup.string().required("Este campo es obligatorio"),
        }}
        buttonName="RESTABLECER"
        redirect={
          <Link to="/">
            Volver al <span>Inicio de sesión</span>
          </Link>
        }
        data={handleToJson(data)}
      />
    );
  }
};

const FormLogin = (props) => {
  const {
    data,
    title,
    fields,
    validationSchema,
    onSubmit,
    buttonName,
    redirect,
  } = props;

  return (
    <div
      style={{
        backgroundImage: `url("${data.backgroundImage}")`,
      }}
      className="bg-cover bg-center flex justify-center items-center w-screen h-screen"
    >
      <FormStyle data={data}>
        <div className="bg-[#ffffffa1] w-full p-8 mx-4 rounded-2xl flex flex-col justify-center items-center sm:w-[400px]">
          <img
            className="w-[70px] sm:w-[120px]"
            src={data.logo}
            alt="logo de trazo"
          />
          <H1 className="text-[25px]">{title}</H1>
          <div className="w-full">
            <FormDefault
              fields={fields}
              initialValues={{
                UsernameOrEmail: "",
                password: "",
              }}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={onSubmit}
              classNameBtn={`text-white`}
              buttonName={buttonName}
            />
          </div>
          <H3 className="text-gray-700 mt-2">
            {redirect ? (
              redirect
            ) : (
              <Link to="/recover">
                ¿Olvidaste tu <span>contraseña</span>?
              </Link>
            )}
          </H3>
        </div>
      </FormStyle>
      <img
        className="w-[70px] hidden absolute left-0 bottom-0 sm:w-[120px] m-6 sm:block"
        src={Recurso_3logo}
        alt="logo de trazo"
      />
    </div>
  );
};

const FormStyle = styled.div`
  & h1 {
    color: ${(props) => `#${props.data.color1}`};
  }
  & label {
    color: ${(props) => `#${props.data.color2}`};
  }
  & button {
    background: ${(props) => `#${props.data.color_buttons}`};
  }
  & span {
    color: ${(props) => `#${props.data.color1}`};
  }
`;
export default Login;
