// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import logotipoGA from "../../assets/LogotipoGA.png";
import logotipoLosAndes from "../../assets/LogotipoLosAndes.png";
import config from "../../config.json";
import { Circle } from "../../components/Animation";
import { validateArray } from "../../utils/validate";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import { useAuth } from "../../contexts/AuthContext";

const RenderPdf = (props) => {
  const { user } = useAuth();
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/rvd`,
  });

  // async function uploadFile(blob) {
  //   var file = new File([blob], 'filename.pdf', {
  //     type: 'application/pdf',
  //     lastModified: Date.now(),
  //   })
  //   let formData = new FormData()
  //   formData.append('Name', 'prueba231')
  //   formData.append('File', file)
  //   formData.append('Type', 'File')
  //   formData.append('CompanyId', 1)
  //   console.log(formData)
  //   await requestAuth('post', '/files', formData)
  //     .then((res) => {
  //       console.log(res)
  //     })
  //     .catch(() => {})
  // }

  if (!loading) {
    return <Circle />;
  }

  function handleBreakDown(list) {
    let newList = [];
    if (validateArray(list)) {
      list.forEach((field) => {
        if (validateArray(field.fileStatusList)) {
          field.fileStatusList.forEach((file) => {
            newList.push(file);
          });
        }
      });
    }
    return newList;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent>
          <View
            width="33%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={config.isLosAndes ? logotipoLosAndes : logotipoGA}
              style={{ width: "160px" }}
            />
          </View>
          <View
            width="34%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <Text textAlign="center" fontSize="11px" bold>
              RECEPCIÓN Y VERIFICACIÓN DE DOCUMENTOS
            </Text>
          </View>
          <View width="33%" flexDirection="column">
            <View border={{ bottom: true }}>
              <Text minWidth="50%" bold border={{ right: true }}>
                Fecha de Inicio:
              </Text>
              <Text>{dayjs(data.initialDate).format("DD/MM/YYYY HH:mm")}</Text>
            </View>
            <View border={{ bottom: true }}>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número Sistema:
              </Text>
              <Text>{data.systemNum}</Text>
            </View>
            <View border={{ bottom: true }}>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número Interno:
              </Text>
              <Text>{data.internNumber}</Text>
            </View>
            <View>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número de DIM:
              </Text>
              <Text>{data.numberC}</Text>
            </View>
          </View>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#1a1a1a" }}
        >
          <Text color="white" bold>
            I. DATOS DEL TRAMITE
          </Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Cliente:
          </Text>
          <Text>{data.client}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Mercadería:
          </Text>
          <Text>{data.product}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Aduana:
          </Text>
          <Text>{data.custom}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Régimen Aduanero:
          </Text>
          <Text>{data.customsRegime}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              Procedencia:
            </Text>
            <Text>{data.origin}</Text>
          </View>
          <View minWidth="50%">
            <Text bold border={{ right: true, left: true }}>
              Número de Factura:
            </Text>
            <Text>{data.invoiceNumber}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Documento de embarque:
          </Text>
          <Text>{data.shipingDoc}</Text>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#1a1a1a" }}
        >
          <Text color="white" bold>
            II. RECEPCIÓN Y VERIFICACIÓN DE DOCUMENTOS
          </Text>
        </View>
        <CustomTablePDF
          user={user}
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "13",
            },
            {
              label: "Documento",
              name: "name",
              width: "17",
            },
            {
              label: "Enlace",
              name: "url",
              type: "link",
              width: "9",
            },
            {
              label: "Número",
              name: "number",
              width: "19",
            },
            {
              label: "Presentación",
              name: "presentation",
              width: "11",
            },
            {
              label: "Estado",
              name: "status",
              width: "13",
            },
            {
              label: "Recepción",
              name: "provider",
              width: "18",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={handleBreakDown(data.rvdoc)}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderPdf;
