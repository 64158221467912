import * as Yup from "yup";
import { toast } from "react-toastify";
import { H3, Anchor } from "../../components/Text";
import { useParams } from "react-router-dom";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import {
  RenderDAV,
  RenderDAM,
  RenderDIM,
  CloneItems,
} from "../../containers/GenerateExcel";

const GenerateExcel = ({ review = false }) => {
  const params = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();
  function handleCopy(item, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres clonar el item?</H3>}
        onClose={dialogClose}
        method="post"
        url={`/procedureSheet/item/${item.id}/clone?place=down`}
        handleSuccess={reload}
        texBtn="Clonar"
      />
    );
  }

  function handleDelete(_, reload, text, url) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar {text}?</H3>}
        onClose={dialogClose}
        method="delete"
        url={url}
        handleSuccess={reload}
        texBtn="Eliminar"
      />
    );
  }

  function exportExcel() {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres generar el excel?</H3>}
        onClose={dialogClose}
        method="post"
        url={`/Files/ExcelDataGenerator/procedure/${params.procedureId}/${params.document}`}
        handleSuccess={viewLinkExcel}
      />
    );
  }

  function viewLinkExcel(data) {
    const link = data.split("XlsxFolder");
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={`http://194.113.210.169${link[1]}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  function importExcel(call, url) {
    openModal(
      <FormUpload
        document={params.document}
        procedureId={params.procedureId}
        onClose={onClose}
        call={call}
        url={url}
      />
    );
  }

  return (
    <div className="h-full">
      {
        {
          "clone-items": (
            <CloneItems
              {...params}
              exportExcel={exportExcel}
              importExcel={importExcel}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              review={review}
            />
          ),
          dav: (
            <RenderDAV
              {...params}
              exportExcel={exportExcel}
              importExcel={importExcel}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              review={review}
            />
          ),
          dam: (
            <RenderDAM
              {...params}
              exportExcel={exportExcel}
              importExcel={importExcel}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              review={review}
            />
          ),
          dim: (
            <RenderDIM
              {...params}
              exportExcel={exportExcel}
              importExcel={importExcel}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              review={review}
            />
          ),
        }[params.document]
      }
    </div>
  );
};

const FormUpload = (props) => {
  const { document, procedureId, onClose, call, url } = props;
  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("file", values.File);
    formData.append("originalName", values.File.name);
    await requestAuth(
      "post",
      url ? url : `/files/ExcelDataReader/procedure/${procedureId}/${document}`,
      formData
    )
      .then(() => {
        call();
        onClose();
        toast.success("Datos extraídos");
      })
      .catch(() => {
        toast.error("No se pudo extraer los datos");
      });
  }

  return (
    <div>
      <FormDefault
        tittle="Formulario para extraer los datos"
        fields={[
          {
            label: "Adjuntar archivo excel",
            name: "File",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          File: null,
        }}
        validationSchema={Yup.object().shape({
          File: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={onSubmit}
        buttonName="Extraer"
      />
    </div>
  );
};

export default GenerateExcel;
