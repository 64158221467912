import { Request } from "../../hooks/Request";
import logotipoGA from "../../assets/LogotipoGA.png";
import logotipoLosAndes from "../../assets/LogotipoLosAndes.png";
import config from "../../config.json";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image, Link } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import { validateArray } from "../../utils/validate";

const GenerateRoadmap = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/roadMap`,
  });
  if (!loading) {
    return <Circle />;
  }

  function handleBreakDown(list) {
    let newList = [];
    if (validateArray(list)) {
      list.forEach((field) => {
        if (validateArray(field.fileStatusList)) {
          field.fileStatusList.forEach((file) => {
            newList.push(file);
          });
        }
      });
    }
    return newList;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View alignItems="center">
          <View width="50%">
            <Image
              src={config.isLosAndes ? logotipoLosAndes : logotipoGA}
              style={{ width: "200px" }}
            />
          </View>
          <View width="25%" flexDirection="column" alignItems="flex-end">
            <Text bold fontSize="10px">
              INTERNO N° IMP-0:
            </Text>
            <Text bold fontSize="10px">
              SOLICITUD N°:
            </Text>
            <Text bold fontSize="10px">
              MODALIDAD:
            </Text>
            <Text bold fontSize="10px">
              ADUANA:
            </Text>
            <Text bold fontSize="10px">
              N° DIM:
            </Text>
          </View>
          <View width="25%" flexDirection="column">
            <Text fontSize="10px">{data.interno}</Text>
            <Text fontSize="10px">{data.solicitud}</Text>
            <Text fontSize="10px">{data.modalidad}</Text>
            <Text fontSize="10px">{data.aduana}</Text>
            <Text fontSize="10px">{data.nroDim}</Text>
          </View>
        </View>
        <Text
          bold
          fontSize="16px"
          color="#ce0707"
          textAlign="center"
          style={{ textDecoration: "underline", margin: "6px 0" }}
        >
          HOJA DE RUTA
        </Text>
        <View>
          <Text fontSize="10px" minWidth="50%">
            <Text fontSize="10px" bold>
              IMPORTADOR:
            </Text>{" "}
            {data.importador !== "null" ? data.importador : "-"}
          </Text>
          <Text fontSize="10px">
            <Text fontSize="10px" bold>
              PROVEEDOR:
            </Text>{" "}
            {data.proveedor !== "null" ? data.proveedor : "-"}
          </Text>
        </View>
        <View>
          <Text fontSize="10px" minWidth="50%">
            <Text fontSize="10px" bold>
              MERCADERÍA:
            </Text>{" "}
            {data.mercaderia !== "null" ? data.mercaderia : "-"}
          </Text>
          <Text fontSize="10px">
            <Text fontSize="10px" bold>
              FACTURA:
            </Text>{" "}
            {data.factura !== "null" ? data.factura : "-"}
          </Text>
        </View>
        <Text bold borderContent style={{ marginTop: 8 }}>
          CONTROLES Y SEGUIMIENTO
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "13",
            },
            {
              label: "Control",
              name: "label",
              width: "40",
            },
            {
              label: "Observación",
              name: "value",
              width: "40",
            },
            {
              label: "Enlace",
              name: "fileStatusList",
              type: "custom",
              width: "9",
              builder: (item) => {
                return validateArray(item) ? (
                  <View flexDirection="column">
                    {item.map((url) => (
                      <View>
                        <Link src={url.url}>
                          <Text color="#1d4ed8">Documento</Text>
                        </Link>
                      </View>
                    ))}
                  </View>
                ) : null;
              },
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={data.controles}
        />
        <Text bold border={{ right: true, bottom: true, left: true }}>
          RECEPCIÓN Y VERIFICACIÓN DE DOCUMENTOS
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "13",
            },
            {
              label: "Documento",
              name: "name",
              width: "20",
            },
            {
              label: "Enlace",
              name: "url",
              type: "link",
              width: "9",
            },
            {
              label: "Número",
              name: "number",
              width: "15",
            },
            {
              label: "Presentación",
              name: "presentation",
              width: "11",
            },
            {
              label: "Estado",
              name: "status",
              width: "13",
            },
            {
              label: "Recepción",
              name: "provider",
              width: "19",
            },
          ]}
          data={handleBreakDown(data.rvDoc)}
          border={{ right: true, bottom: true, left: true }}
        />
        <Text bold border={{ right: true, bottom: true, left: true }}>
          INICIO DE LA INCIDENCIA
        </Text>
        <Text minWidth="50%" border={{ right: true, bottom: true, left: true }}>
          {data.inicioIncidencia && data.inicioIncidencia.value !== "null"
            ? data.inicioIncidencia.value
            : "-"}
        </Text>
        {data.inicioIncidencia &&
        validateArray(data.inicioIncidencia.fileStates) ? (
          <CustomTablePDF
            fields={[
              {
                label: "Descripción",
                name: "description",
                width: "90",
              },
              {
                label: "Enlace",
                name: "url",
                type: "link",
                width: "10",
              },
            ]}
            border={{ right: true, bottom: true, left: true }}
            data={data.inicioIncidencia.fileStates}
          />
        ) : null}
        <Text bold border={{ right: true, bottom: true, left: true }}>
          CIERRE DE LA INCIDENCIA
        </Text>
        <Text minWidth="50%" border={{ right: true, bottom: true, left: true }}>
          {data.cierreIncidencia && data.cierreIncidencia.value !== "null"
            ? data.cierreIncidencia.value
            : "-"}
        </Text>
        {data.cierreIncidencia &&
        validateArray(data.cierreIncidencia.fileStates) ? (
          <CustomTablePDF
            fields={[
              {
                label: "Descripción",
                name: "description",
                width: "90",
              },
              {
                label: "Enlace",
                name: "url",
                type: "link",
                width: "10",
              },
            ]}
            border={{ right: true, bottom: true, left: true }}
            data={data.cierreIncidencia.fileStates}
          />
        ) : null}
        <Text bold border={{ right: true, bottom: true, left: true }}>
          ENTREGA DE MERCADERÍA
        </Text>
        <View>
          <Text
            minWidth="50%"
            border={{ right: true, bottom: true, left: true }}
          >
            <Text bold>Peso entregado:</Text>{" "}
            {data.kilosEntregados !== "null" ? data.kilosEntregados : "-"}
          </Text>
          <Text minWidth="50%" border={{ right: true, bottom: true }}>
            <Text bold>Tipo de transporte:</Text>{" "}
            {data.tipoDeTransporte !== "null" ? data.tipoDeTransporte : "-"}
          </Text>
        </View>
        <View>
          <Text
            minWidth="50%"
            border={{ right: true, bottom: true, left: true }}
          >
            <Text bold>Placa de transporte:</Text>{" "}
            {data.placaDeTransporte !== "null" ? data.placaDeTransporte : "-"}
          </Text>
          <Text minWidth="50%" border={{ right: true, bottom: true }}>
            <Text bold>Nombre del conductor:</Text>{" "}
            {data.nombreDelConductor !== "null" ? data.nombreDelConductor : "-"}
          </Text>
        </View>
        <View>
          <Text
            minWidth="50%"
            border={{ right: true, bottom: true, left: true }}
          >
            <Text bold>Cantidad de estibadores:</Text>{" "}
            {data.cantidadDeEstibadores !== "null"
              ? data.cantidadDeEstibadores
              : "-"}
          </Text>
          <Text minWidth="50%" border={{ right: true, bottom: true }}>
            <Text bold>Transporte adicional:</Text>{" "}
            {data.transporteAdicional !== "null"
              ? data.transporteAdicional
              : "-"}
          </Text>
        </View>
        <View>
          <Text
            minWidth="50%"
            border={{ right: true, bottom: true, left: true }}
          >
            <Text bold>Fotografía del transporte:</Text>{" "}
            {data.fotografíaDelTransporte !== "null" &&
            data.fotografíaDelTransporte !== "-" ? (
              <Link src={data.fotografíaDelTransporte}>
                <Text color="#1d4ed8">Enlace</Text>
              </Link>
            ) : (
              "-"
            )}
          </Text>
          <Text minWidth="50%" border={{ right: true, bottom: true }}>
            <Text bold>Responsable entrega de mercadería:</Text>{" "}
            {data.responsableEntregaDeMercaderia !== "null"
              ? data.responsableEntregaDeMercaderia
              : "-"}
          </Text>
        </View>
        <Text bold border={{ right: true, bottom: true, left: true }}>
          <Text bold>Número de referencia:</Text>{" "}
          {data.number !== "null" ? data.number : "-"}
        </Text>
        <View>
          <Text
            minWidth="50%"
            border={{ right: true, bottom: true, left: true }}
          >
            <Text bold>Lugar de entrega:</Text>{" "}
            {data.placeOfDelivery !== "null" ? data.placeOfDelivery : "-"}
          </Text>
          <Text minWidth="50%" border={{ right: true, bottom: true }}>
            <Text bold>Fecha de entrega:</Text>{" "}
            {data.dateOfDelivery !== "null" ? data.dateOfDelivery : "-"}
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateRoadmap;
