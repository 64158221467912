import config from "../config.json";
import { useAuth } from "./AuthContext";
import { requestAuth } from "../components/services/RequestService";
import { useState, createContext, useContext, useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { user } = useAuth();
  const [connection, setConnection] = useState({
    connect: null,
    connectionId: null,
  });

  useEffect(() => {
    if (user && user !== undefined) {
      handleSocket();
    }
  }, [user]);

  function handleSocket() {
    const connect = new HubConnectionBuilder()
      .withUrl(
        `${
          config?.hostname2 === window.location.hostname
            ? config.requestURL2
            : config?.hostname === window.location.hostname
            ? config.requestURL
            : config.requestURL
        }/notificationHub`,
      )
      .withAutomaticReconnect()
      .build();

    setConnection((a) => ({
      ...a,
      connect: connect,
    }));
  }

  function setConnectionId(connectionId) {
    setConnection((a) => ({ ...a, connectionId: connectionId }));
  }

  return (
    <SocketContext.Provider value={{ connection, setConnectionId }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
